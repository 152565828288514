// src/apiClient.js
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_MAIN_API_URL,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.error &&
      error.response.data.error.includes('Session expired')
    ) {
      // Dispatch a global event for session expiration
      window.dispatchEvent(new Event('sessionExpired'));
    }
    return Promise.reject(error);
  }
);

export default api;
