//src/FileEditorModal.js

import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Spinner, Alert, Form } from 'react-bootstrap';
import AvatarEditor from 'react-avatar-editor';

const FileEditorModal = ({ show, onClose, file, disputeId, onSave }) => {
  console.log("⚠️ onSave() TRIGGERED");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [scale, setScale] = useState(1.0);
  const [content, setContent] = useState(null);

  const editorRef = useRef(null);
  const canvasRef = useRef(null);
  const [rectangles, setRectangles] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPos, setStartPos] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const isImage = file ? /\.(jpg|jpeg|png|gif)$/i.test(file.filename) : false;

   

  useEffect(() => {
    if (show && file) {
      if (isImage) {
        fetch(`${process.env.REACT_APP_MAIN_API_URL}/api/disputes/files/${file.filename}?raw=true`, {
          method: 'GET',
          credentials: 'include',
        })
          .then(response => {
            if (!response.ok) throw new Error('Unauthorized or file not found');
            return response.blob();
          })
          .then(blob => {
            setContent(URL.createObjectURL(blob));
            setLoading(false);
          })
          .catch(err => {
            setError(err.message);
            setLoading(false);
          });
      }
    }
  }, [show, file, isImage]);

  // Draw the rectangles on the canvas
  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas
    ctx.fillStyle = 'rgba(0, 0, 0, 0.8)'; // Black with slight transparency

    rectangles.forEach(({ x, y, width, height }) => {
      ctx.fillRect(x, y, width, height); // Draw the rectangle
    });
  }, [rectangles]); // Re-draw every time rectangles change

  const startDrawing = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    setStartPos({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    setIsDrawing(true);
  };

  const drawRect = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const currentPos = { x: e.clientX - rect.left, y: e.clientY - rect.top };

    const newRectangle = {
      x: startPos.x,
      y: startPos.y,
      width: currentPos.x - startPos.x,
      height: currentPos.y - startPos.y,
    };

    setRectangles((prev) => [...prev, newRectangle]);
  };

  const stopDrawing = () => {
    setIsDrawing(false);
  };

  const handleSave = () => {
    console.log("🛠️ handleSave() CALLED");
  
    if (isSaving) {
      console.log("🚫 handleSave() BLOCKED (Already saving)");
      return; // Stop duplicate requests
    }
  
    setIsSaving(true);
    
    if (isImage && editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      const ctx = canvas.getContext("2d");
  
      // 🔹 Apply redactions (draw black rectangles)
      ctx.fillStyle = "black";
      rectangles.forEach(({ x, y, width, height }) => {
        ctx.fillRect(x, y, width, height);
      });
  
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("🚨 ERROR: Blob not created");
          setIsSaving(false);
          return;
        }
  
        const formData = new FormData();
        formData.append("file", blob, file.filename);
        formData.append("disputeId", disputeId);
  
        console.log("🔹 SENDING FILE UPDATE REQUEST...");
  
        fetch(`${process.env.REACT_APP_MAIN_API_URL}/api/disputes/files/${file.filename}`, {
          method: "PUT",
          credentials: "include",
          body: formData,
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              throw new Error(data.error);
            }
            console.log(`✅ SUCCESS: File uploaded as ${data.newFilename}`);
            alert(`File uploaded successfully as: ${data.newFilename}`);
            setIsSaving(false);
            onSave(); // Refresh UI
          })
          .catch((err) => {
            console.error("❌ ERROR Saving File:", err);
            alert("Error saving file: " + err.message);
            setIsSaving(false);
          });
      }, "image/png");
    }
  };
  
  
  
  
  
  
  
  
  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit File: {file && file.filename}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Spinner animation="border" />
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : (
          <>
            {isImage ? (
              <div style={{ position: 'relative', display: 'inline-block' }}>
                <AvatarEditor
                  ref={editorRef}
                  image={content}
                  width={400}
                  height={400}
                  border={50}
                  color={[255, 255, 255, 0.6]}
                  scale={scale}
                  rotate={0}
                />
                <canvas
                  ref={canvasRef}
                  width={400}
                  height={400}
                  style={{
                    position: 'absolute',
                    top: 50, // Adjust to align with AvatarEditor
                    left: 50,
                    pointerEvents: 'auto',
                  }}
                  onMouseDown={startDrawing}
                  onMouseMove={drawRect}
                  onMouseUp={stopDrawing}
                />
                <Form.Label className="mt-2">Zoom</Form.Label>
                <Form.Range
                  min="1"
                  max="3"
                  step="0.1"
                  value={scale}
                  onChange={(e) => setScale(parseFloat(e.target.value))}
                />
              </div>
            ) : (
              <Form.Control
                as="textarea"
                rows={15}
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => setRectangles([])}>
          Clear Redactions
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={isSaving}>
        {isSaving ? "Saving..." : "Save & Verify"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileEditorModal;
