// Button/Settings.js
import React, { useState } from "react";
import { FaCog } from "react-icons/fa";
import '../App.css';

const SettingsButton = ({ scale = 100, onClick }) => {
  const [spinning, setSpinning] = useState(false);
  const baseSize = 24;
  const scaledSize = baseSize * (scale / 100);

  const handleClick = () => {
    setSpinning(true);
    onClick();
    setTimeout(() => setSpinning(false), 1000);
  };

  return (
    <div
      role="button"
      onClick={handleClick}
      className="inline-flex transform items-center justify-center p-2 icntop-bg rounded-full focus:outline-none transition-transform duration-1000 ease-in-out"
      // Remove text-white or any forced color classes so that the inline style takes effect.
      style={{ color: "var(--sidebar-text)" }}
    >
      <FaCog size={scaledSize} className={spinning ? "animate-spin-once" : ""} />
    </div>
  );
};

export default SettingsButton;
