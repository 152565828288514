// src/pages/MediationHub.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import './dashboard.css';

const API_URL = process.env.REACT_APP_MAIN_API_URL;

const DisputeResolution = () => {
  const { user } = useAuth();
  const [disputes, setDisputes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDispute, setSelectedDispute] = useState(null);
  const [resolutionDecision, setResolutionDecision] = useState({ winner: '', loser: '' });
  const [messageText, setMessageText] = useState('');
  const [feedback, setFeedback] = useState('');

  // Fetch active disputes from the server and filter by mediator email.
  const fetchDisputes = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_URL}/disputes/active`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error('Failed to fetch disputes');
      }
      const data = await response.json();
      // Filter disputes where the mediator (user.email) is assigned
      const mediatorEmail = user.email.toLowerCase();
      const myDisputes = data.disputes.filter((d) =>
        d.selectedMediators.map((m) => m.toLowerCase()).includes(mediatorEmail)
      );
      setDisputes(myDisputes);
    } catch (error) {
      setFeedback(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDisputes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Select a dispute to view details.
  const handleSelectDispute = (dispute) => {
    setSelectedDispute(dispute);
    setFeedback('');
  };

  // Handle dispute resolution decision submission.
  const handleResolve = async (e) => {
    e.preventDefault();
    try {
      setFeedback('');
      const payload = {
        disputeId: selectedDispute._id,
        winner: resolutionDecision.winner,
        loser: resolutionDecision.loser,
      };
      const res = await fetch(`${API_URL}/disputes/resolve`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(payload),
      });
      const resData = await res.json();
      if (!res.ok) {
        throw new Error(resData.error || 'Failed to resolve dispute');
      }
      setFeedback('Dispute resolved successfully.');
      // Refresh disputes list and clear selected dispute
      fetchDisputes();
      setSelectedDispute(null);
      setResolutionDecision({ winner: '', loser: '' });
    } catch (error) {
      setFeedback(error.message);
    }
  };

  // Handle sending a message request for more information.
  const handleSendMessage = async (e) => {
    e.preventDefault();
    try {
      setFeedback('');
      const payload = {
        sender: user.email,
        text: messageText,
        fromServer: true, // mediator messages may be flagged as fromServer
      };
      // We use the wallet id from the dispute to send the message.
      const res = await fetch(`${API_URL}/disputes/messages/${selectedDispute.wallet}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(payload),
      });
      const resData = await res.json();
      if (!res.ok) {
        throw new Error(resData.error || 'Failed to send message');
      }
      setFeedback('Message sent successfully.');
      setMessageText('');
    } catch (error) {
      setFeedback(error.message);
    }
  };

  return (
    <div className="dashboard-container">
      <h2 className="header">Dispute Resolution Panel</h2>
      {feedback && <div className="alert alert-info">{feedback}</div>}
      {loading ? (
        <p>Loading disputes...</p>
      ) : !selectedDispute ? (
        // Wrap disputes list in a bubble for consistent styling
        <div className="bubble">
          <h3 className="header">Your Assigned Active Disputes</h3>
          {disputes.length === 0 ? (
            <p>No active disputes assigned to you at this time.</p>
          ) : (
            <ul className="list-group">
              {disputes.map((dispute) => (
                <li key={dispute._id} className="list-group-item bg-dark text-light">
                  <p>
                    <strong>Wallet ID:</strong> {dispute.wallet}
                  </p>
                  <p>
                    <strong>Initiator:</strong> {dispute.initiator}
                  </p>
                  <p>
                    <strong>Evidence:</strong> {dispute.evidence}
                  </p>
                  <button
                    className="btn btn-primary btn-sm mt-2"
                    onClick={() => handleSelectDispute(dispute)}
                  >
                    View Details
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      ) : (
        // Wrap dispute details in a bubble as well
        <div className="bubble">
          <button className="btn btn-secondary mb-3" onClick={() => setSelectedDispute(null)}>
            Back to Dispute List
          </button>
          <h3 className="header">Dispute Details</h3>
          <p>
            <strong>Wallet:</strong> {selectedDispute.wallet}
          </p>
          <p>
            <strong>Initiator:</strong> {selectedDispute.initiator}
          </p>
          <p>
            <strong>Evidence:</strong> {selectedDispute.evidence}
          </p>
          <p>
            <strong>Status:</strong> {selectedDispute.status}
          </p>
          <p>
            <strong>Selected Mediators:</strong> {selectedDispute.selectedMediators.join(', ')}
          </p>

          <hr />

          <h4>Resolve Dispute</h4>
          <form onSubmit={handleResolve}>
            <div className="mb-3">
              <label htmlFor="winner" className="form-label">
                Winner Email
              </label>
              <input
                type="text"
                id="winner"
                className="form-control"
                value={resolutionDecision.winner}
                onChange={(e) =>
                  setResolutionDecision({ ...resolutionDecision, winner: e.target.value })
                }
                placeholder="Enter winner's email"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="loser" className="form-label">
                Loser Email
              </label>
              <input
                type="text"
                id="loser"
                className="form-control"
                value={resolutionDecision.loser}
                onChange={(e) =>
                  setResolutionDecision({ ...resolutionDecision, loser: e.target.value })
                }
                placeholder="Enter loser's email"
                required
              />
            </div>
            <button type="submit" className="btn btn-success">
              Submit Resolution
            </button>
          </form>

          <hr />

          <h4>Request More Information</h4>
          <form onSubmit={handleSendMessage}>
            <div className="mb-3">
              <label htmlFor="messageText" className="form-label">
                Message
              </label>
              <textarea
                id="messageText"
                className="form-control"
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                placeholder="Type your message here..."
                required
              ></textarea>
            </div>
            <button type="submit" className="btn btn-info">
              Send Message
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default DisputeResolution;
