// src/pages/DisputeDetails.js

import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Modal,
  Button,
  Form,
  Alert,
  Spinner,
  Card,
  ListGroup,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import { io } from 'socket.io-client';
import Cookies from 'js-cookie';
import './dashboard.css';

// Helper to normalize email
function normalizeEmail(email) {
  return email.toLowerCase().replace(/\./g, '_');
}

const userEmail = Cookies.get('userEmail');

const DisputeDetails = () => {
  // List of disputes for the user (fetched from GET /api/disputes)
  const [disputes, setDisputes] = useState([]);
  // The dispute selected from the list (may contain partial data)
  const [selectedDispute, setSelectedDispute] = useState(null);
  // Store the wallet id used to fetch dispute details.
  const [activeWalletId, setActiveWalletId] = useState(null);
  // Modal visibility control.
  const [showModal, setShowModal] = useState(false);

  // States for dispute details (chat, files, etc.) inside the modal.
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [sendingMessage, setSendingMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [myFiles, setMyFiles] = useState([]);

  const messagesEndRef = useRef(null);
  const [socket, setSocket] = useState(null);

  // --- Fetch all disputes for the user on mount ---
  useEffect(() => {
    const fetchDisputes = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/disputes`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });
        if (!res.ok) {
          const errText = await res.text();
          throw new Error(errText || 'Failed to fetch disputes');
        }
        const data = await res.json();
        console.log("Fetched disputes:", data.disputes);
        setDisputes(data.disputes || []);
      } catch (err) {
        console.error('Error fetching disputes:', err);
      }
    };
    fetchDisputes();
  }, []);

  // --- When a dispute is selected, set activeWalletId to trigger fetching details ---
  const openModal = (disputeItem) => {
    setSelectedDispute(disputeItem);
    const walletId =
      typeof disputeItem.wallet === 'object'
        ? (disputeItem.wallet._id || disputeItem.wallet.toString())
        : disputeItem.wallet;
    setActiveWalletId(walletId);
    setShowModal(true);
  };

  // --- Fetch dispute details and user files when activeWalletId changes ---
  useEffect(() => {
    if (!activeWalletId) return;

    // Reset previous details state.
    setLoading(true);
    setError(null);
    setMessages([]);
    setMyFiles([]);

    const fetchDisputeDetails = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/disputes/wallet/${activeWalletId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });
        if (!res.ok) {
          const errText = await res.text();
          throw new Error(errText || 'Failed to load dispute details');
        }
        const data = await res.json();
        // Update selected dispute details (but do not update activeWalletId)
        setSelectedDispute(data.dispute);

        // Merge messages from dispute.messages.
        const messagesObj = data.dispute.messages || {};
        let allMessages = [];
        for (const key in messagesObj) {
          if (Object.hasOwnProperty.call(messagesObj, key)) {
            allMessages = allMessages.concat(messagesObj[key]);
          }
        }
        allMessages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        setMessages(allMessages);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching dispute details:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    const fetchMyFiles = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/disputes/my-files/${activeWalletId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });
        if (!res.ok) {
          throw new Error('Failed to load your files');
        }
        const data = await res.json();
        setMyFiles(data.files);
      } catch (err) {
        console.error('Error fetching my files:', err);
      }
    };

    fetchDisputeDetails();
    fetchMyFiles();
  }, [activeWalletId]);

  // --- Setup socket connection for real-time chat when activeWalletId changes ---
  useEffect(() => {
    if (!activeWalletId) return;
    const newSocket = io(process.env.REACT_APP_MAIN_API_URL, {
      withCredentials: true,
      query: { walletId: activeWalletId },
      transports: ['websocket'],
    });
    const disputeRoom = `dispute_${activeWalletId}`;
    newSocket.emit('joinPrivateRoom', disputeRoom);
    newSocket.on('chatMessage', (newMsg) => {
      setMessages((prev) => [...prev, newMsg]);
    });
    setSocket(newSocket);

    return () => {
      newSocket.emit('leavePrivateRoom', disputeRoom);
      newSocket.off('chatMessage');
      newSocket.disconnect();
    };
  }, [activeWalletId]);

  // --- Auto-scroll chat to the latest message ---
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // --- Handle sending a chat message ---
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!message.trim() || !activeWalletId) return;
    setSendingMessage(true);
    try {
      const payload = { sender: normalizeEmail(userEmail), text: message };
      const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/disputes/messages/${activeWalletId}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(payload),
      });
      if (!res.ok) {
        const errorText = await res.text();
        throw new Error(errorText || 'Failed to send message');
      }
      const data = await res.json();
      setMessages((prev) => [...prev, data.message]);
      setMessage('');
    } catch (err) {
      console.error('Error sending message:', err);
    } finally {
      setSendingMessage(false);
    }
  };

  // --- Close modal and clear dispute-specific state ---
  const closeModal = () => {
    setShowModal(false);
    setSelectedDispute(null);
    setActiveWalletId(null);
    setMessages([]);
    setMyFiles([]);
  };

  return (
    <Container className="my-4">
      <h2>Your Disputes</h2>
      {disputes.length === 0 ? (
        <Alert variant="info">No disputes found.</Alert>
      ) : (
        <ListGroup>
          {disputes.map((d) => (
            <ListGroup.Item key={d._id} action onClick={() => openModal(d)}>
              {d.walletName || d._id}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}

      {/* Dispute Details Modal */}
      <Modal show={showModal} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Dispute Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" />
              <p>Loading dispute details...</p>
            </div>
          ) : error ? (
            <Alert variant="danger">{error}</Alert>
          ) : selectedDispute ? (
            <>
              <h3>{selectedDispute.walletName || selectedDispute._id}</h3>
              {/* Chat Section */}
              <Card className="mb-4">
                <Card.Body style={{ maxHeight: '300px', overflowY: 'auto' }}>
                  {messages.length === 0 ? (
                    <Alert variant="info">No messages yet.</Alert>
                  ) : (
                    <ListGroup variant="flush">
                      {messages.map((msg, idx) => {
                        const displaySender =
                          msg.sender === 'server'
                            ? 'Server'
                            : msg.sender === normalizeEmail(userEmail)
                            ? 'You'
                            : msg.sender;
                        return (
                          <ListGroup.Item key={idx}>
                            <div className="d-flex justify-content-between">
                              <strong>{displaySender}</strong>
                              <small>{new Date(msg.createdAt).toLocaleTimeString()}</small>
                            </div>
                            <p className="mb-0">{msg.text}</p>
                          </ListGroup.Item>
                        );
                      })}
                      <div ref={messagesEndRef} />
                    </ListGroup>
                  )}
                </Card.Body>
                <Card.Footer>
                  <Form onSubmit={handleSendMessage}>
                    <InputGroup>
                      <FormControl
                        placeholder="Type your message..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <Button variant="primary" type="submit" disabled={sendingMessage || !message.trim()}>
                        {sendingMessage ? 'Sending...' : 'Send'}
                      </Button>
                    </InputGroup>
                  </Form>
                </Card.Footer>
              </Card>
              {/* Files Section */}
              <Card className="mb-4">
                <Card.Header>Your Uploaded Files</Card.Header>
                <Card.Body>
                  {myFiles.length === 0 ? (
                    <Alert variant="info">You have not uploaded any files.</Alert>
                  ) : (
                    <ListGroup variant="flush">
                      {myFiles.map((file, idx) => (
                        <ListGroup.Item key={idx}>
                          <a
                            href={`${process.env.REACT_APP_MAIN_API_URL}/disputes/files/${file.filename}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {file.filename}
                          </a>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                </Card.Body>
              </Card>
            </>
          ) : (
            <Alert variant="info">No dispute selected.</Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default DisputeDetails;
