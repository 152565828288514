// AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { io } from 'socket.io-client';
import SessionWarningModal from './Modals/SessionWarningModal';
import SessionExpiredModal from './Modals/SessionExpiredModal';
import api from './apiClient'; // our Axios instance

export const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  const [sessionCanceled, setSessionCanceled] = useState(false);
  const [socket, setSocket] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  // Clear the sessionCanceled flag when on the login page.
  useEffect(() => {
    if (location.pathname === '/login') {
      setSessionCanceled(false);
    }
  }, [location.pathname]);

  // Listen for the global "sessionExpired" event dispatched by the Axios interceptor.
  // Only show the modal if the user is authenticated and not on the login page.
  useEffect(() => {
    const handleSessionExpired = () => {
      if (authenticated && location.pathname !== '/login') {
        console.log("Global sessionExpired event received.");
        setSessionCanceled(true);
      }
    };
    window.addEventListener('sessionExpired', handleSessionExpired);
    return () => window.removeEventListener('sessionExpired', handleSessionExpired);
  }, [authenticated, location.pathname]);

  // Initialize WebSocket only after authentication
useEffect(() => {
  if (authenticated) {
    const newSocket = io(process.env.REACT_APP_MAIN_API_URL, {
      withCredentials: true,
    });
    
    newSocket.on('connect', () => {
      console.log(`✅ Connected to WebSocket: ${newSocket.id}`);
    });
    
    newSocket.on('forceLogout', () => {
      console.log('🔴 forceLogout event received! Showing session expiration modal.');
      if (location.pathname !== '/login') {
        setSessionCanceled(true);
      }
    });
    
    setSocket(newSocket);
    
    return () => {
      console.log(`⚠️ Disconnecting socket: ${newSocket.id}`);
      newSocket.disconnect();
    };
  }
}, [authenticated, location.pathname]);

  
  // Emit "userLogin" event when user and socket are ready
  useEffect(() => {
    if (user && socket && user.sessionId) {
      console.log(`🔹 Joining WebSocket room for session: ${user.sessionId}`);
      socket.emit('userLogin', { userId: user._id, sessionId: user.sessionId });
    }
  }, [user, socket]);
  
  // Fetch user session on mount using our Axios instance
  useEffect(() => {
    async function checkAuth() {
      try {
        const res = await api.get('/auth/user-info');
        console.log('User info response:', res.data);
        setAuthenticated(true);
        setUser(res.data);
        if (socket) {
          socket.emit('userLogin', { userId: res.data._id, sessionId: res.data.sessionId });
        }
      } catch (error) {
        console.error('Auth error:', error);
        setAuthenticated(false);
        setUser(null);
      } finally {
        setLoading(false);
      }
    }
    checkAuth();
  }, [socket]);

  // Redirect authenticated users from /home to dashboard
  useEffect(() => {
    if (authenticated && location.pathname === '/home') {
      navigate('/multisig/dashboard', { replace: true });
    }
  }, [authenticated, location, navigate]);

  useEffect(() => {
    if (authenticated && (location.pathname === '/login')) {
      navigate('/multisig/dashboard', { replace: true });
    }
  }, [authenticated, location.pathname, navigate]);

  // Session expiration warning (for extending session)
  useEffect(() => {
    if (authenticated) {
      const warningTime = 14 * 60 * 1000; // 2 minutes before expiration
      const timer = setTimeout(() => setShowWarning(true), warningTime);
      return () => clearTimeout(timer);
    }
  }, [authenticated]);

  // Extend session handler
  const handleExtendSession = async () => {
    setShowWarning(false);
    try {
      const res = await api.post('/auth/refresh');
      if (res.status === 200) {
        alert('Session extended!');
      } else {
        alert('Unable to extend session. You will be logged out.');
        logoutAndExpire();
      }
    } catch (error) {
      console.error('Error extending session:', error);
      logoutAndExpire();
    }
  };

  // Logout function
  const logout = async () => {
    console.log("🔥 Attempting to logout via API...");
    try {
      const res = await api.post('/auth/logout');
      if (!res.data.message) {
        console.warn("⚠️ Logout API responded with an error.");
      } else {
        console.log("✅ Logout API call successful.");
      }
    } catch (err) {
      console.error('[AuthContext] Logout request failed:', err);
    }
  
    console.log("🚀 Clearing authentication state...");
    setAuthenticated(false);
    setUser(null);
  
    if (socket) {
      socket.disconnect();
    }
    navigate('/login', { replace: true });
    
  };

  const logoutAndExpire = async () => {
    setShowWarning(false);
    await logout();
  };

  // Handle session expiration confirmation from the modal.
  // When the user clicks "OK", clear the modal and log out (redirecting to the login page).
  const handleSessionExpiredOk = () => {
    console.log("🔴 User acknowledged session expiration. Now logging out.");
    setSessionCanceled(false);
    logout();
  };

  const value = { authenticated, setAuthenticated, user, setUser, loading, logout };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
      {showWarning && (
        <SessionWarningModal
          duration={10}
          onExtend={handleExtendSession}
          onExpire={logoutAndExpire}
        />
      )}
      {authenticated && sessionCanceled && <SessionExpiredModal onOk={handleSessionExpiredOk} />}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
