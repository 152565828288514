// SessionWarningModal.js
import React, { useState, useEffect } from 'react';
import './SessionWarningModal.css';

const SessionWarningModal = ({ duration = 10, onExtend, onExpire }) => {
  const [secondsLeft, setSecondsLeft] = useState(duration);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSecondsLeft(prev => {
        if (prev <= 1) {
          clearInterval(intervalId);
          onExpire(); // Automatically log out when the timer reaches 0
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [onExpire]);

  return (
    <div className="modal-overlay">
      <div className="session-modal">
        <p>Your session is about to expire in {secondsLeft} seconds. Do you need more time?</p>
        <button onClick={onExtend}>Extend Session</button>
      </div>
    </div>
  );
};

export default SessionWarningModal;
