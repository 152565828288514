//Modals/SettingsModal.js

import React, { useState, useEffect } from 'react';
import { Modal, Container, Row, Col, Form, Button, Table, Alert } from 'react-bootstrap';

export default function SettingsModal({ show, handleClose }) {
  const [transactionAddresses, setTransactionAddresses] = useState([]);
  const [newAddress, setNewAddress] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('success');

  // Fetch saved transaction addresses when the modal is shown.
  useEffect(() => {
    if (show) {
      fetch(`${process.env.REACT_APP_MAIN_API_URL}/api/transaction-addresses`, {

        credentials: 'include',
      })
        .then((res) => res.json())
        .then((data) => setTransactionAddresses(data.addresses || []))
        .catch((err) => console.error('Error fetching addresses:', err));
    }
  }, [show]);

  const handleAddAddress = () => {
    if (!newAddress) return;
    fetch(`${process.env.REACT_APP_MAIN_API_URL}/api/transaction-addresses`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ address: newAddress }),
    })
      .then((res) => {
        if (!res.ok) throw new Error('Failed to add address');
        return res.json();
      })
      .then(() => {
        setTransactionAddresses([...transactionAddresses, newAddress]);
        setNewAddress('');
        setAlertMessage('Address added successfully.');
        setAlertVariant('success');
      })
      .catch((err) => {
        console.error(err);
        setAlertMessage('Error adding address.');
        setAlertVariant('danger');
      });
  };

  const handleRemoveAddress = (address) => {
    fetch(`${process.env.REACT_APP_MAIN_API_URL}/api/transaction-addresses/${encodeURIComponent(address)}`, {
      method: 'DELETE',
      credentials: 'include',
    })
      .then((res) => {
        if (!res.ok) throw new Error('Failed to remove address');
        setTransactionAddresses(transactionAddresses.filter((a) => a !== address));
        setAlertMessage('Address removed successfully.');
        setAlertVariant('success');
      })
      .catch((err) => {
        console.error(err);
        setAlertMessage('Error removing address.');
        setAlertVariant('danger');
      });
  };

  const handleDownloadHistory = () => {
    // Redirect the browser to the download endpoint.
    window.location.href = `${process.env.REACT_APP_MAIN_API_URL}/api/transactions/history/download`;

  };

  return (
    <Modal 
      show={show} 
      onHide={handleClose} 
      size="lg" 
      centered
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alertMessage && (
          <Alert variant={alertVariant} onClose={() => setAlertMessage('')} dismissible>
            {alertMessage}
          </Alert>
        )}
        <Container>
          <Row className="mb-4">
            <Col md={6}>
              <h4>Download Transaction History</h4>
              <p>
                Download your complete transaction history as a CSV file for your records.
              </p>
              <Button variant="primary" onClick={handleDownloadHistory}>
                Download History
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <h4>Manage Transaction Addresses</h4>
              <p>
                Maintain a list of frequently used transaction addresses for quick access.
              </p>
              <Form inline className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Enter new address"
                  value={newAddress}
                  onChange={(e) => setNewAddress(e.target.value)}
                  className="mr-2"
                />
                <Button variant="success" onClick={handleAddAddress}>
                  Add Address
                </Button>
              </Form>
              {transactionAddresses.length > 0 ? (
                <Table striped bordered hover variant="dark">
                  <thead>
                    <tr>
                      <th>Address</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionAddresses.map((address, index) => (
                      <tr key={index}>
                        <td>{address}</td>
                        <td>
                          <Button variant="danger" size="sm" onClick={() => handleRemoveAddress(address)}>
                            Remove
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p>No saved transaction addresses.</p>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
