//BitcoinMultisigApp.js

import { useState, useEffect } from "react";
import {
  FaTachometerAlt,
  FaWallet,
  FaExchangeAlt,
  FaUser,
  FaGavel,
  FaSignOutAlt,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import MultisigRoutes from "./multisig/Routes";
import MediatorOptIn from "./MediatorOptIn";
import DarkToggleButton from "./Button/Dark";
import SettingsButton from "./Button/Settings";
import SettingsModal from "./Modals/SettingsModal"; // Import your Settings modal
import "./App.css";

const BitcoinMultisigApp = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  // Set creative tab title
    useEffect(() => {
      document.title = "Multisig Vault | Bitcoin Security Fortified";
    }, []);
  

  // Toggle dark mode by adding/removing the 'dark' class on the root element
  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    console.log("Dark mode is", darkMode ? "enabled" : "disabled");
  }, [darkMode]);

  const toggleDarkMode = () => {
    console.log("toggleDarkMode called");
    setDarkMode(!darkMode);
  };

  // Base menu items for the sidebar
  const menuItems = [
    { icon: <FaTachometerAlt size={20} />, label: "Dashboard", path: "/multisig/dashboard" },
    { icon: <FaWallet size={20} />, label: "Wallets", path: "/multisig/dashboard?view=wallets" },
    { icon: <FaExchangeAlt size={20} />, label: "Transactions", path: "/multisig/transactions" },
    { icon: <FaUser size={20} />, label: "Profile", path: "/multisig/updateInfo" },
  ];

  let isMediator = false;
  let hasTransaction = false;
  if (user) {
    isMediator = user.isMediator === true || user.isMediator === "true";
    hasTransaction = user.hasTransaction === true || user.hasTransaction === "true";
  }

  if (user && isMediator) {
    menuItems.splice(4, 0, {
      icon: <FaGavel size={20} />,
      label: "Mediation Hub",
      path: "/multisig/mediation-hub",
    });
  }

  menuItems.push({
    icon: <FaSignOutAlt size={24} />,
    label: "Sign Out",
    isLogout: true,
    action: async () => {
      await logout();
      navigate('/login');
    },
    
  });

  return (
    // Add the "gradient-bg" class so that the background uses the CSS-defined gradients
    <div className="h-screen w-screen flex flex-col relative gradient-bg">
      {/* Header */}
      <div
        className="sticky top-0 z-50 p-4 flex justify-between items-center shadow-md"
        style={{ background: "var(--header-bg)", color: "var(--header-text)" }}
      >
        <h1 className="text-xl font-semibold">Bitcoin Multisig</h1>
        <div className="flex items-center space-x-4">
          <SettingsButton onClick={() => setShowSettingsModal(true)} scale={100} />
          <DarkToggleButton onClick={toggleDarkMode} />
        </div>
      </div>

      <div className="flex flex-1 w-full relative">
        {/* Sidebar */}
        <div
          className={`flex flex-col p-2 pr-2 shadow-lg transition-all duration-300 ease-in-out ${
            sidebarExpanded ? "w-55" : "w-12"
          } fixed left-0 top-22 h-full z-50`}
          style={{ background: "var(--sidebar-bg)", color: "var(--sidebar-text)" }}
          onMouseEnter={() => setSidebarExpanded(true)}
          onMouseLeave={() => setSidebarExpanded(false)}
        >
          {menuItems.map((item, index) => (
            <div
              key={index}
              className={`flex items-center px-2 py-3 rounded-lg cursor-pointer transition-all duration-300 hover:bg-blue-600 ${
                item.isLogout ? "text-red-400 hover:text-red-300" : ""
              }`}
              onClick={() => {
                if (item.action) item.action();
                else navigate(item.path);
              }}
            >
              <span className="w-10 flex justify-start items-center">{item.icon}</span>
              <span
                className={`text-sm whitespace-nowrap transition-opacity duration-300 ${
                  sidebarExpanded ? "opacity-100 ml-2" : "opacity-0"
                }`}
              >
                {item.label}
              </span>
            </div>
          ))}
          {sidebarExpanded && user && !isMediator && hasTransaction && (
            <div className="mt-4">
              <MediatorOptIn />
            </div>
          )}
        </div>

        {/* Content Area */}
        <div className="p-6 flex-1 overflow-auto ml-0">
          <MultisigRoutes />
        </div>
      </div>
      
      {/* Settings Modal */}
      <SettingsModal show={showSettingsModal} handleClose={() => setShowSettingsModal(false)} />
    </div>
  );
};

export default BitcoinMultisigApp;
