import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import '../pages/dashboard.css';

export default function DisputeModal({
  show,
  onHide,
  walletId,
  disputeIdentityProvided,
  setDisputeIdentityProvided, // (optional if you need to update it from within)
  onInitiateDispute,
  setShowKycModal,
}) {
  const [disputeLevel, setDisputeLevel] = useState('random_mediators');
  const [disputeEvidence, setDisputeEvidence] = useState('');
  const [disputeFiles, setDisputeFiles] = useState(null);

  async function handleSubmit() {
    if (!disputeIdentityProvided) {
      const proceed = window.confirm(
        'You have not verified your identity. This may delay or deny dispute resolution. Continue?'
      );
      if (!proceed) return;
    }
    if (!window.confirm('Initiate dispute with selected settings?')) return;
    try {
      const formData = new FormData();
      formData.append('walletId', walletId);
      formData.append('disputeLevel', disputeLevel);
      formData.append('evidence', disputeEvidence);
      formData.append('identityProvided', disputeIdentityProvided);
      if (disputeFiles) {
        Array.from(disputeFiles).forEach((file) => {
          formData.append('files', file);
        });
      }
      await onInitiateDispute(formData);
      onHide();
    } catch (err) {
      console.error('[DisputeModal] Dispute initiation error:', err);
      alert('Failed to initiate dispute: ' + err.message);
    }
  }

  return (
    <Modal show={show} onHide={onHide} container={document.body}>
      <Modal.Header closeButton>
        <Modal.Title>Initiate Dispute</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="disputeLevel">
            <Form.Label>Dispute Level</Form.Label>
            <Form.Control
              as="select"
              value={disputeLevel}
              onChange={(e) => setDisputeLevel(e.target.value)}
            >
              <option value="random_mediators">Random Mediators (5 users)</option>
              <option value="employees">Webapp Employees ($50 fee)</option>
              <option value="arbitration">Arbitration</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="disputeEvidence" className="mt-3">
            <Form.Label>What went wrong? Provide detailed explanation:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={disputeEvidence}
              onChange={(e) => setDisputeEvidence(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Identity Verification</Form.Label>
            {disputeIdentityProvided ? (
              <p style={{ color: 'lightgreen' }}>Your identity has been verified.</p>
            ) : (
              <button type="button" className="button" onClick={() => setShowKycModal(true)}>
                Verify Identity
              </button>
            )}
          </Form.Group>
          <Form.Group controlId="disputeFiles" className="mt-3">
            <Form.Label>Upload supporting files (JPEG, JPG, PNG, GIF, PDF)</Form.Label>
            <Form.Control
              type="file"
              multiple
              accept="image/jpeg, image/jpg, image/png, image/gif, application/pdf"
              onChange={(e) => setDisputeFiles(e.target.files)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className="button" onClick={onHide}>
          Cancel
        </button>
        <button className="button" onClick={handleSubmit}>
          Initiate Dispute
        </button>
      </Modal.Footer>
    </Modal>
  );
}
