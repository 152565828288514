// src/EmployeeInterface.js
import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Button,
  Spinner,
  Alert,
  Form,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { io } from 'socket.io-client';
import FileEditorModal from '../Modals/FileEditorModal';






const EmployeeInterface = () => {
  const [disputes, setDisputes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDispute, setSelectedDispute] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [selectedRecipient, setSelectedRecipient] = useState(''); // empty means broadcast
  const navigate = useNavigate();
  const { logout, user } = useAuth();
  const chatContainerRef = useRef(null);
  
  // Editor modal state
  const [editorModalVisible, setEditorModalVisible] = useState(false);
  const [fileToEdit, setFileToEdit] = useState(null);

  // Functions to open/close the file editor modal
  const openEditor = (file) => {
    setFileToEdit(file);
    setEditorModalVisible(true);
  };

  const closeEditor = () => {
    setEditorModalVisible(false);
    setFileToEdit(null);
  };

  // Helper function to render wallet as a string.
  const renderWallet = (wallet) => {
    if (typeof wallet === 'object' && wallet !== null) {
      return wallet.walletName || wallet._id;
    }
    return wallet;
  };

  // Fetch pending disputes for employees using cookies for authentication.
  useEffect(() => {
    const fetchDisputes = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/api/disputes/pending`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });
        if (!res.ok) {
          const errText = await res.text();
          throw new Error(errText || 'Failed to load disputes');
        }
        const data = await res.json();
        setDisputes(data.disputes || []);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching disputes:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchDisputes();
  }, []);

  // Auto-scroll the chat container to the bottom when chatMessages update.
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  // Establish a Socket.IO connection only when a dispute is selected.
  useEffect(() => {
    if (selectedDispute) {
      const walletId =
        typeof selectedDispute.wallet === 'object'
          ? selectedDispute.wallet._id
          : selectedDispute.wallet;
      const newSocket = io(process.env.REACT_APP_MAIN_API_URL, {
        withCredentials: true,
        query: { walletId },
        transports: ['websocket'],
      });
      newSocket.emit('joinPrivateRoom', `dispute_${walletId}`);

      newSocket.on('chatMessage', (newMsg) => {
        console.log('EmployeeInterface received new message:', newMsg);
        setChatMessages((prev) => [...prev, newMsg]);
      });

      return () => {
        newSocket.emit('leavePrivateRoom', `dispute_${walletId}`);
        newSocket.off('chatMessage');
        newSocket.disconnect();
      };
    }
  }, [selectedDispute]);

  // Fetch dispute details (including messages and files) when "View Details" is clicked.
  const handleViewDetails = async (walletIdToUse) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/api/disputes/wallet/${walletIdToUse}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });
      if (!res.ok) {
        throw new Error('Failed to fetch dispute details');
      }
      const data = await res.json();
      setSelectedDispute(data.dispute);

      // Merge messages from all keys.
      const messagesObj = data.dispute.messages || {};
      let allMessages = [];
      for (const key in messagesObj) {
        if (messagesObj.hasOwnProperty(key)) {
          allMessages = allMessages.concat(messagesObj[key]);
        }
      }
      allMessages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      setChatMessages(allMessages);

      // Reset recipient selection (default to broadcast).
      setSelectedRecipient('');
    } catch (err) {
      console.error(err);
      alert('Failed to fetch dispute details');
    }
  };

  const handleFileSave = async (updatedContent) => {
    console.log("⚠️ handleFileSave() TRIGGERED"); // Debugging log
    
    try {

  
      // ✅ Fix: Check if the file was ALREADY updated in the modal.
      if (!fileToEdit || !fileToEdit.filename) {
        console.error("🚨 No file selected for saving.");
        return;
      }
  
      console.log(`✅ File ${fileToEdit.filename} already saved in modal. Skipping duplicate request.`);
  
      closeEditor(); // ✅ Close modal without sending another PUT request.
    } catch (err) {
      alert('Error saving file: ' + err.message);
    }
  };
  

  async function handleLogout() {
    await logout();
    navigate('/');
  }

  // When sending a message from the employee interface.
  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;
    try {
      const payload = {
        text: newMessage,
        fromServer: true,
        sender: user.email,
      };
      if (selectedRecipient) {
        payload.target = selectedRecipient;
      }
      const walletIdToUse =
        typeof selectedDispute.wallet === 'object'
          ? selectedDispute.wallet._id
          : selectedDispute.wallet;
      const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/api/disputes/messages/${walletIdToUse}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(payload),
      });
      if (!res.ok) {
        throw new Error('Failed to send message');
      }
      const data = await res.json();
      console.log('EmployeeInterface sent message:', data.message);
      setChatMessages((prev) => [...prev, data.message]);
      setNewMessage('');
    } catch (err) {
      console.error(err);
      alert('Failed to send message');
    }
  };
// Overwrite verified file with original
const handleOverwriteVerifiedFile = async (originalFile) => {
  const disputeId = selectedDispute._id;
  const verifiedFilename = originalFile.filename.replace(/\.(\w+)$/, "-verified.$1");

  try {
    const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/api/disputes/files/overwrite`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ disputeId, originalFilename: originalFile.filename, verifiedFilename }),
    });

    const data = await res.json();
    if (!res.ok) throw new Error(data.error || "Failed to overwrite verified file");
    alert("Verified file has been overwritten with the original.");
    handleViewDetails(disputeId); // Refresh dispute details
  } catch (err) {
    alert("Error overwriting verified file: " + err.message);
  }
};

// Delete the verified file
const handleDeleteVerifiedFile = async (verifiedFilename) => {
  const disputeId = selectedDispute._id;

  try {
    const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/api/disputes/files/${verifiedFilename}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ disputeId }),
    });

    const data = await res.json();
    if (!res.ok) throw new Error(data.error || "Failed to delete verified file");
    alert("Verified file deleted successfully.");
    handleViewDetails(disputeId); // Refresh dispute details
  } catch (err) {
    alert("Error deleting verified file: " + err.message);
  }
};

const handleActivateDispute = async (disputeId) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/api/disputes/activate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ disputeId }),
    });
    const data = await res.json();
    if (!res.ok) {
      throw new Error(data.error || 'Failed to activate dispute');
    }
    alert(data.message);
    // Refresh the disputes list after activation.
    setLoading(true);
    const disputesRes = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/api/disputes/pending`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    });
    const disputesData = await disputesRes.json();
    setDisputes(disputesData.disputes || []);
    setLoading(false);
  } catch (err) {
    console.error(err);
    alert('Error activating dispute: ' + err.message);
  }
};

  // Compute participants from dispute.wallet.participants, excluding the current employee.
  const participants =
    selectedDispute && selectedDispute.wallet && selectedDispute.wallet.participants
      ? selectedDispute.wallet.participants.filter(
          (p) => p.email.toLowerCase() !== user.email.toLowerCase()
        )
      : [];

  return (
    <Container fluid className="my-4">
      <Row>
        {/* Sidebar Navigation */}
        <Col md={3}>
          <Card className="mb-4">
            <Card.Header>Employee Navigation</Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item action onClick={() => navigate('/employee')}>
                Home
              </ListGroup.Item>
              <ListGroup.Item action onClick={() => navigate('/employee/disputes')}>
                Pending Disputes
              </ListGroup.Item>
              <ListGroup.Item action onClick={() => navigate('/employee/email')}>
                Email
              </ListGroup.Item>
              <ListGroup.Item action onClick={() => navigate('/employee/users')}>
                User Management
              </ListGroup.Item>
              <ListGroup.Item action onClick={() => navigate('/employee/reports')}>
                Reports
              </ListGroup.Item>
              <ListGroup.Item action onClick={() => navigate('/UpdateUserInfo')}>
                Update Profile
              </ListGroup.Item>
              <ListGroup.Item action onClick={handleLogout} style={{ color: 'red' }}>
                Logout
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
        {/* Main Content Area */}
        <Col md={9}>
          <Card className="mb-4">
            <Card.Header>Dashboard Overview</Card.Header>
            <Card.Body>
              <h4>Pending Disputes</h4>
              {loading ? (
                <Spinner animation="border" />
              ) : error ? (
                <Alert variant="danger">{error}</Alert>
              ) : disputes.length === 0 ? (
                <Alert variant="info">There are no pending disputes at the moment.</Alert>
              ) : (
                <ListGroup>
                  {disputes.map((d) => {

                    return (
                      <ListGroup.Item key={d._id}>
  <div className="d-flex justify-content-between align-items-center">
    <div>
      <strong>Wallet:</strong> {renderWallet(d.wallet)} <br />
      <small>Level: {d.disputeLevel}</small>
    </div>
    <div>
      <Button
        variant="primary"
        size="sm"
        onClick={() => handleViewDetails(
          typeof d.wallet === 'object' ? d.wallet._id : d.wallet
        )}
      >
        View Details
      </Button>
      {/* Activate dispute button */}
      <Button
        variant="success"
        size="sm"
        className="ms-2"
        onClick={() => handleActivateDispute(d._id)}
      >
        Activate
      </Button>
    </div>
  </div>
</ListGroup.Item>

                    );
                  })}
                </ListGroup>
              )}
            </Card.Body>
          </Card>

          {/* Chat Section for Selected Dispute */}
          {selectedDispute && (
            <Card className="mb-4">
              <Card.Header>
                Chat for Wallet: <strong>{renderWallet(selectedDispute.wallet)}</strong>
              </Card.Header>
              <Card.Body style={{ maxHeight: '400px', overflowY: 'auto' }} ref={chatContainerRef}>
                {chatMessages.length === 0 ? (
                  <Alert variant="info">No messages yet.</Alert>
                ) : (
                  <ListGroup>
                    {chatMessages.map((msg, index) => (
                      <ListGroup.Item key={index}>
                        <div>
                          <strong>{msg.sender === user.email ? 'You' : msg.sender}:</strong>{' '}
                          {msg.text}
                        </div>
                        <small className="text-muted">
                          {new Date(msg.createdAt).toLocaleString()}
                        </small>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </Card.Body>
              <Card.Footer>
                {/* Recipient Dropdown for employees */}
                <Form.Group controlId="recipientSelect" className="mb-2">
                  <Form.Label>Reply to:</Form.Label>
                  <Form.Select
                    value={selectedRecipient}
                    onChange={(e) => setSelectedRecipient(e.target.value)}
                  >
                    <option value="">Broadcast (All)</option>
                    {participants.map((p) => (
                      <option key={p.email} value={p.email}>
                        {p.email}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <InputGroup>
                  <FormControl
                    type="text"
                    placeholder="Type your message..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                  />
                  <Button variant="primary" onClick={handleSendMessage}>
                    Send
                  </Button>
                </InputGroup>
                <div className="mt-2">
                  <Button variant="secondary" onClick={() => setSelectedDispute(null)}>
                    Close Chat
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          )}

          {/* Section: Display all files linked to the selected dispute */}
          {selectedDispute && (
  <Card className="mb-4">
    <Card.Header>Files Attached to Dispute</Card.Header>
    <Card.Body>
      {selectedDispute.files && selectedDispute.files.length > 0 ? (
        <ListGroup variant="flush">
{selectedDispute.files.map((file, idx) => {
  // Use the originalFilename if available; otherwise fall back to filename.
  const originalLink = file.originalFilename ? file.originalFilename : file.filename;
  const verifiedLink = file.verified && file.verifiedFilename ? file.verifiedFilename : null;

  return (
    <ListGroup.Item key={idx}>
      <div className="d-flex justify-content-between align-items-center">
        {/* Original File Link */}
        <a
          href={`${process.env.REACT_APP_MAIN_API_URL}/api/disputes/files/${originalLink}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {originalLink} (Original)
        </a>

        {/* Verified File Link */}
        {verifiedLink && (
          <a
            href={`${process.env.REACT_APP_MAIN_API_URL}/api/disputes/files/${verifiedLink}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {verifiedLink} (Verified)
          </a>
        )}

        {/* Action Buttons */}
        <div>
          <Button variant="outline-primary" size="sm" onClick={() => openEditor(file)}>
            Edit
          </Button>
          <Button variant="warning" size="sm" onClick={() => handleOverwriteVerifiedFile(file)}>
            Overwrite Verified
          </Button>
          <Button variant="danger" size="sm" onClick={() => handleDeleteVerifiedFile(verifiedLink)}>
            Delete Verified
          </Button>
        </div>
      </div>
    </ListGroup.Item>
  );
})}


        </ListGroup>
      ) : (
        <Alert variant="info">No files attached to this dispute.</Alert>
      )}
    </Card.Body>
  </Card>
)}

        
        </Col>
      </Row>
      {/* Render the FileEditorModal */}
      <FileEditorModal
        show={editorModalVisible}
        onClose={closeEditor}
        file={fileToEdit}
        disputeId={selectedDispute ? selectedDispute._id : null}
        onSave={handleFileSave}
      />
    </Container>
  );
};

export default EmployeeInterface;
