// src/ApprovalPopup.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Button, Alert, Form } from 'react-bootstrap';
import CryptoJS from 'crypto-js';

const API_URL = `${process.env.REACT_APP_MAIN_API_URL}`;


const ApprovalPopup = () => {
  const { walletId, transactionId } = useParams();
 

  // State for approval details (transaction info)
  const [approvalDetails, setApprovalDetails] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [detailsError, setDetailsError] = useState('');

  // State for submission messages and button state.
  const [submitMessage, setSubmitMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('info');
  const [submitting, setSubmitting] = useState(false);

  // State for private key input (manually entered)
  const [privateKeyInput, setPrivateKeyInput] = useState('');
  // State for uploaded key file content (if user uploads instead of typing)
  const [uploadedKey, setUploadedKey] = useState('');
  // State for encryption passphrase (to encrypt the private key before sending)
  const [encryptionPassphrase, setEncryptionPassphrase] = useState('');

  // Determine whether the current approval requires the final approver's private key.
  // This is based on the number of current approvals and the total number of participants.
  let isFinalApprover = false;
  if (approvalDetails) {
    const participantsCount = approvalDetails.participantsCount || 0;
    const threshold = Math.floor(participantsCount / 2) + 1;
    const currentApprovals = approvalDetails.approvals ? approvalDetails.approvals.length : 0;
    // If the current number of approvals equals threshold - 1, this user must supply their private key.
    if (currentApprovals === threshold - 1) {
      isFinalApprover = true;
    }
  }

  // Fetch the approval details on mount.
  useEffect(() => {
    async function fetchApprovalDetails() {
      try {
        const res = await fetch(
          `${API_URL}/wallets/${walletId}/transaction/${transactionId}/approval-details`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include', // <-- critical for cookie-based auth
          }
        );
        if (!res.ok) {
          throw new Error('Failed to fetch approval details');
        }
        const data = await res.json();
        setApprovalDetails(data);
      } catch (err) {
        setDetailsError(err.message || 'Error fetching details');
      } finally {
        setLoadingDetails(false);
      }
    }
    fetchApprovalDetails();
  }, [walletId, transactionId]);

  // Handler for file upload. Reads the uploaded file and stores its content.
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const fileContent = event.target.result;
        // Assume the file content is plain text containing the private key (or an encrypted key).
        setUploadedKey(fileContent.trim());
      } catch (error) {
        console.error('Error reading file:', error);
      }
    };
    reader.readAsText(file);
  };

  // Handler for approving the transaction.
  const handleApprove = async () => {
    setSubmitting(true);
    setSubmitMessage('');
    let finalPrivateKey = '';

    if (isFinalApprover) {
      // For final approvers, require a private key either from the uploaded file or manual input.
      finalPrivateKey = uploadedKey || privateKeyInput;
      if (!finalPrivateKey) {
        setSubmitMessage('Final approver must provide their private key.');
        setAlertVariant('danger');
        setSubmitting(false);
        return;
      }
      // Require an encryption passphrase.
      if (!encryptionPassphrase) {
        setSubmitMessage('Please enter an encryption passphrase.');
        setAlertVariant('danger');
        setSubmitting(false);
        return;
      }
      // Encrypt the private key before sending.
      finalPrivateKey = CryptoJS.AES.encrypt(finalPrivateKey, encryptionPassphrase).toString();
    }

    try {
      const payload = {};
      if (isFinalApprover) {
        payload.privateKey = finalPrivateKey;
      }

      const res = await fetch(
        `${API_URL}/wallets/${walletId}/transaction/${transactionId}/approve`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include', // <-- critical for cookie-based auth
          body: JSON.stringify(payload),
        }
      );

      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.error || 'Approval failed');
      }

      setAlertVariant('success');
      setSubmitMessage('Approved');
      // After a 2-second delay, refresh the parent and close the popup.
      setTimeout(() => {
        if (window.opener) {
          window.opener.location.reload();
        }
        window.close();
      }, 2000);
    } catch (err) {
      console.error('[ApprovalPopup] Approval error:', err);
      setAlertVariant('danger');
      setSubmitMessage(err.message || 'Failed to approve transaction.');
      setSubmitting(false);
    }
  };

  return (
    <Modal show onHide={() => window.close()} centered>
      <Modal.Header closeButton onHide={() => window.close()}>
        <Modal.Title>Confirm Transaction Approval</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loadingDetails ? (
          <p>Loading transaction details…</p>
        ) : detailsError ? (
          <Alert variant="danger">{detailsError}</Alert>
        ) : (
          <>
            <div>
              <p><strong>Wallet:</strong> {approvalDetails.walletName}</p>
              <p><strong>Destination Address:</strong> {approvalDetails.toAddress}</p>
              <p>
                <strong>Amount:</strong> {approvalDetails.amount} BTC<br />
                <strong>Transaction Fee:</strong> {approvalDetails.transactionFee} BTC<br />
                <strong>Net Amount:</strong>{' '}
                {approvalDetails.amount - approvalDetails.transactionFee} BTC
              </p>
              <p>Please click the checkmark below to approve this transaction.</p>
            </div>
            {isFinalApprover && (
              <>
                <Alert variant="info" className="mt-3">
                  As the final approver, you must provide your private key to complete the transaction.
                </Alert>
                <Form.Group controlId="privateKeyInput" className="mt-2">
                  <Form.Label>Enter Private Key (WIF)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your private key"
                    value={privateKeyInput}
                    onChange={(e) => setPrivateKeyInput(e.target.value)}
                    disabled={uploadedKey !== ''}
                  />
                </Form.Group>
                <Form.Group controlId="fileUpload" className="mt-2">
                  <Form.Label>Or Upload Key File</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".json,.txt"
                    onChange={handleFileUpload}
                  />
                  {uploadedKey && (
                    <Alert variant="success" className="mt-2">
                      Key file uploaded.
                    </Alert>
                  )}
                </Form.Group>
                <Form.Group controlId="encryptionPassphrase" className="mt-2">
                  <Form.Label>Encryption Passphrase</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter passphrase to encrypt your key"
                    value={encryptionPassphrase}
                    onChange={(e) => setEncryptionPassphrase(e.target.value)}
                  />
                </Form.Group>
              </>
            )}
            {submitMessage && (
              <Alert variant={alertVariant} className="mt-3">
                {submitMessage}
              </Alert>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => window.close()} disabled={submitting}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleApprove}
          disabled={submitting || loadingDetails || detailsError}
        >
          {submitting ? 'Approving…' : 'Approve Transaction'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApprovalPopup;
