// src/KeyGenPopup.js

import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import CryptoJS from 'crypto-js';
import useAuthUser from '../hooks/useAuthUser'; // Ensure this exists in src/hooks/
import './KeyGenPopup.css';

const BASE_URL = process.env.REACT_APP_MAIN_SERVER_URL;

// Fetch wallet info (with credentials for cookie-based auth)
async function fetchWalletInfo(walletId) {
  const res = await fetch(`${BASE_URL}/api/wallets/${walletId}`, {
    method: 'GET',
    credentials: 'include',
  });
  if (!res.ok) {
    throw new Error('Failed to fetch wallet info');
  }
  return await res.json();
}

// Confirm wallet
async function confirmWallet(walletId) {
  const res = await fetch(`${BASE_URL}/api/wallets/${walletId}/confirm`, {
    method: 'POST',
    credentials: 'include',
  });
  const data = await res.json();
  if (!res.ok) {
    throw new Error(data.error || 'Failed to confirm wallet');
  }
  return data;
}

// Check session status
async function getSessionStatus(sessionId) {
  const res = await fetch(`${BASE_URL}/api/wallets/${sessionId}/status`, {
    method: 'GET',
    credentials: 'include',
  });
  const data = await res.json();
  if (!res.ok) {
    throw new Error(data.error || 'Failed to get session status');
  }
  return data;
}

const KeyGenPopup = () => {
  const { walletId } = useParams();
  const { user, loading } = useAuthUser();
  // Use secure cookie–based email instead of localStorage
  const userEmail = user ? user.email.toLowerCase() : '';

  // Ephemeral state
  const [status, setStatus] = useState('Loading wallet info...');
  const [expectedParticipants, setExpectedParticipants] = useState([]);
  const [readyList, setReadyList] = useState({});
  const [confirmed, setConfirmed] = useState(false);
  const [keys, setKeys] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [storedPrivateKey, setStoredPrivateKey] = useState(null);

  // UI states
  const [dotCount, setDotCount] = useState(0);
  const [showPassphrase, setShowPassphrase] = useState(false);
  const [passphrase1, setPassphrase1] = useState('');
  const [passphrase2, setPassphrase2] = useState('');
  const [walletFileContent, setWalletFileContent] = useState('');

  // Use a ref to persist the socket connection
  const socketRef = useRef(null);

  // 1) Fetch wallet info on component load.
  useEffect(() => {
    if (!walletId) {
      setStatus('❌ No walletId provided in route params');
      return;
    }
    fetchWalletInfo(walletId)
      .then((wallet) => {
        setStatus('Waiting for participants to open their secure window');
        const participants = (wallet.participants || []).map((p) => p.email.toLowerCase());
        setExpectedParticipants(participants);
        if (wallet.sessionId) {
          setSessionId(wallet.sessionId);
        }
      })
      .catch((err) => {
        console.error('[KeyGenPopup] fetchWalletInfo error:', err);
        setStatus('❌ Error fetching wallet data');
      });
  }, [walletId]);

  // 2) Socket.IO for readiness (ensure user info is loaded first)
  useEffect(() => {
    if (!walletId || loading) return;

    socketRef.current = io(BASE_URL, {
      withCredentials: true,
      path: '/socket.io',
      transports: ['websocket'],
    });

    // Join the wallet room
    socketRef.current.emit('joinWalletRoom', { walletId });
    // Emit popupOpened event to indicate this user is ready
    socketRef.current.emit('popupOpened', { walletId, email: userEmail });
    // Uncomment below for additional debugging:
    // console.log(`Emitted popupOpened with email: ${userEmail} for walletId: ${walletId}`);

    // Listen for readiness updates
    socketRef.current.on('popupStatus', (data) => {
      if (data.walletId === walletId) {
        // Uncomment for debugging:
        // console.log('Received popupStatus:', data);
        setReadyList(data.ready || {});
      }
    });

    // Ensure popupClosed is emitted when window is closed/unloaded.
    const handleBeforeUnload = () => {
      socketRef.current.emit('popupClosed', { walletId, email: userEmail });
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      socketRef.current.emit('popupClosed', { walletId, email: userEmail });
      window.removeEventListener('beforeunload', handleBeforeUnload);
      socketRef.current.off('popupStatus');
      socketRef.current.disconnect();
    };
  }, [walletId, userEmail, loading]);

  // 3) Poll session status after confirmation
  useEffect(() => {
    let intervalId;
    if (confirmed && sessionId) {
      intervalId = setInterval(() => {
        getSessionStatus(sessionId)
          .then((data) => {
            if (data.status === 'active') {
              setStatus('Multisig keys generated successfully');
              setKeys({
                privateKey: storedPrivateKey,
                address: data.multisigAddress || '',
                pubKeys: data.pubKeys || [],
              });
              clearInterval(intervalId);
            }
          })
          .catch((err) => {
            console.error('[KeyGenPopup] error polling session:', err);
          });
      }, 3000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [confirmed, sessionId, storedPrivateKey]);

  // 4) Animated dots for waiting status
  useEffect(() => {
    const isWaiting = /waiting/i.test(status);
    let timer;
    if (isWaiting) {
      timer = setInterval(() => {
        setDotCount((prev) => (prev + 1) % 4);
      }, 500);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [status]);

  // 5) Update status when readiness changes (based on non-server participants)
  useEffect(() => {
    if (!keys && expectedParticipants.length > 0) {
      const nonServerParticipants = expectedParticipants.filter(
        (email) => email !== userEmail && !email.includes('server')
      );
      const readyCount = nonServerParticipants.filter((email) => readyList[email]).length;
      if (readyCount < nonServerParticipants.length) {
        setStatus('Waiting for other participant(s) to open their secure window');
      } else {
        setStatus('Other users are ready. Confirm participation.');
      }
    }
  }, [keys, expectedParticipants, readyList, userEmail]);

  // Compute non-server participants for button enabling/disabling
  const nonServerParticipants = expectedParticipants.filter(
    (email) => email !== userEmail && !email.includes('server')
  );
  const allOthersReady =
    nonServerParticipants.length > 0 &&
    nonServerParticipants.every((email) => readyList[email]);

  // 6) Confirm participation
  const handleConfirm = async () => {
    if (!walletId) {
      setStatus('❌ Invalid wallet parameters (no walletId)');
      return;
    }
    setConfirmed(true);
    setStatus('Confirming...');

    try {
      const data = await confirmWallet(walletId);
      // Re-fetch wallet info to update sessionId in case both users have confirmed
      const updatedWallet = await fetchWalletInfo(walletId);
      if (updatedWallet.sessionId) {
        setSessionId(updatedWallet.sessionId);
      }
      if (data?.multisigSession && data.multisigSession.privateKey) {
        setStoredPrivateKey(data.multisigSession.privateKey);
      }
      setStatus('Waiting on the other user(s) to confirm');
    } catch (err) {
      console.error('❌ Error confirming participation:', err);
      setStatus(`❌ Confirmation error: ${err.message}`);
    }
  };

  // 7) Generate wallet file content once keys are known
  useEffect(() => {
    if (!keys) return;
    const walletData = {
      multisigAddress: keys.address,
      publicKeys: keys.pubKeys,
      privateKey: keys.privateKey,
    };
    const json = JSON.stringify(walletData, null, 2);
    if (passphrase1.trim()) {
      if (passphrase1 === passphrase2) {
        const encrypted = CryptoJS.AES.encrypt(json, passphrase1).toString();
        setWalletFileContent(encrypted);
      } else {
        setWalletFileContent('');
      }
    } else {
      setWalletFileContent(json);
    }
  }, [keys, passphrase1, passphrase2]);

  // 8) Copy to clipboard / Download wallet file functions
  const copyToClipboard = () => {
    if (!walletFileContent) {
      alert('Please ensure passphrases match or that keys are generated.');
      return;
    }
    navigator.clipboard
      .writeText(walletFileContent)
      .then(() => alert('Wallet file copied to clipboard!'))
      .catch((err) => alert('Error copying wallet file: ' + err));
  };

  const downloadWalletFile = () => {
    if (!walletFileContent) {
      alert('Please ensure passphrases match or that keys are generated.');
      return;
    }
    const blob = new Blob([walletFileContent], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'multisig_wallet.json';
    a.click();
    URL.revokeObjectURL(url);
  };

  // Render list of other participants with readiness indicators
  const renderParticipants = () => {
    if (!expectedParticipants.length) return null;
    const others = expectedParticipants.filter(
      (email) => email !== userEmail && !email.includes('server')
    );
    if (!others.length) return null;
    return (
      <div style={{ marginBottom: '15px' }}>
        <h4>Other Participants</h4>
        {others.map((email) => {
          const isReady = Boolean(readyList[email]);
          const displayName = readyList[email] || email;
          return (
            <div key={email} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <div
                style={{
                  width: '12px',
                  height: '12px',
                  borderRadius: '50%',
                  backgroundColor: isReady ? 'green' : 'red',
                  marginRight: '10px',
                }}
              />
              <span>{displayName}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const showAnimatedDots = /waiting|confirming/i.test(status);

  return (
    <div style={{ padding: 20 }}>
      <h2>Secure Key Generation</h2>
      <p>
        Status: {status}{' '}
        {showAnimatedDots && (
          <span style={{ display: 'inline-block', width: '30px', marginLeft: 5 }}>
            {'.'.repeat(dotCount)}
          </span>
        )}
      </p>

      {renderParticipants()}

      {/* Disable Confirm Participation button until all non-server participants are ready */}
      {!confirmed && !keys && (
        <button
          onClick={handleConfirm}
          disabled={!allOthersReady}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            opacity: allOthersReady ? 1 : 0.6,
            cursor: allOthersReady ? 'pointer' : 'not-allowed',
          }}
        >
          Confirm Participation
        </button>
      )}

      {/* Once confirmed and keys are generated, display them */}
      {confirmed && keys && (
        <div style={{ marginTop: 20 }}>
          <h3>Multisig Wallet Details</h3>
          {keys.address && <p><strong>Address:</strong> {keys.address}</p>}
          {keys.pubKeys?.length > 0 && (
            <>
              <p><strong>Public Keys:</strong></p>
              <ul>
                {keys.pubKeys.map((pk) => (
                  <li key={pk}>{pk}</li>
                ))}
              </ul>
            </>
          )}
          {keys.privateKey && (
            <p><strong>Your Private Key (WIF):</strong> {keys.privateKey}</p>
          )}

          <div style={{ marginTop: 20 }}>
            <h4>Secure Wallet File</h4>
            <p>Enter a passphrase (optional) to encrypt your wallet file.</p>
            <input
              type={showPassphrase ? 'text' : 'password'}
              placeholder="Enter passphrase"
              value={passphrase1}
              onChange={(e) => setPassphrase1(e.target.value)}
              style={{ width: '100%', marginBottom: 5 }}
            />
            <input
              type={showPassphrase ? 'text' : 'password'}
              placeholder="Confirm passphrase"
              value={passphrase2}
              onChange={(e) => setPassphrase2(e.target.value)}
              style={{ width: '100%', marginBottom: 5 }}
            />
            {passphrase1 && passphrase2 && passphrase1 !== passphrase2 && (
              <p style={{ color: 'red' }}>Passphrases do not match.</p>
            )}
            <label>
              <input
                type="checkbox"
                checked={showPassphrase}
                onChange={() => setShowPassphrase((prev) => !prev)}
              />{' '}
              Show passphrase
            </label>

            <div style={{ marginTop: 10, display: 'flex', gap: 10 }}>
              <button onClick={copyToClipboard}>Copy Wallet File</button>
              <button onClick={downloadWalletFile}>Download Wallet File</button>
            </div>
          </div>
        </div>
      )}

      <div style={{ marginTop: 20 }}>
        <button onClick={() => window.close()} style={{ padding: '10px 20px' }}>
          Close
        </button>
      </div>
    </div>
  );
};

export default KeyGenPopup;
