// src/api.js
const API_URL = process.env.REACT_APP_MAIN_API_URL;


// --------------------
// Public endpoints (no auth cookie needed, but credentials: 'include' can be added if desired)
// --------------------



export async function customFetch(url, options = {}) {
  const response = await fetch(url, { ...options, credentials: 'include' });
  if (response.status === 401) {
    const errorData = await response.json();
    // Only dispatch if the error message indicates a session expiration due to another login.
    if (errorData.error && errorData.error.includes('Session expired')) {
      window.dispatchEvent(new Event('sessionExpired'));
    }
  }
  return response;
}

export async function isEmailValid(email) {
  try {
    const response = await fetch(
      `${API_URL}/auth/check-email?email=${encodeURIComponent(email)}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        // credentials: 'include', // optional for public routes
      }
    );
    if (!response.ok) throw new Error('Error checking email availability');
    const data = await response.json();
    return data.isValid;
  } catch (error) {
    console.error('Error in isEmailValid:', error);
    throw error;
  }
}

export async function signupUser(
  email,
  password,
  firstName,
  lastName,
  address,
  city,
  state,
  country,
  businessName,
  phoneNumber,
  userType,
  networkType = 'mainnet'
) {
  try {
    const isValid = await isEmailValid(email);
    if (!isValid) {
      throw new Error('Email is already registered. Please login instead.');
    }
    const response = await fetch(`${API_URL}/auth/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      // credentials: 'include' // not needed during signup
      body: JSON.stringify({
        email,
        password,
        firstName: userType === 'individual' ? firstName : null,
        lastName: userType === 'individual' ? lastName : null,
        businessName: userType === 'business' ? businessName : null,
        address,
        city,
        state,
        country,
        phoneNumber,
        userType,
        networkType,
      }),
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.error || 'Signup failed');
    return data;
  } catch (error) {
    throw error;
  }
}

export async function loginUser(email, password) {


  // 2) Actually call the /auth/login route
  const response = await fetch(`${API_URL}/auth/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({ email, password }),
  });
  const data = await response.json();

  // If server returns 401 or any non-OK, throw
  if (!response.ok) {
    throw new Error(data.error || 'Login failed');
  }
  return data;
}


// --------------------
// Authenticated endpoints (the cookie is sent automatically)
// --------------------

export async function getUserInfo(email) {
  try {
    const response = await fetch(
      `${API_URL}/auth/user-info?email=${encodeURIComponent(email)}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include', // Send auth cookie
      }
    );
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || 'Failed to fetch user info');
    }
    return await response.json();
  } catch (error) {
    console.error('API Error (getUserInfo):', error.message);
    throw error;
  }
}

export async function updateUserInfo(email, updatedData) {
  try {
    const response = await fetch(`${API_URL}/auth/update-user-info`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include', // Send auth cookie
      body: JSON.stringify({ email, ...updatedData }),
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.error || 'Failed to update user info');
    return data;
  } catch (error) {
    console.error('API Error (updateUserInfo):', error.message);
    throw error;
  }
}

export async function removeTransaction(walletId, transactionId) {
  try {
    const response = await fetch(
      `${API_URL}/wallets/${walletId}/transaction/${transactionId}/remove`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      }
    );
    const data = await response.json();
    if (!response.ok) throw new Error(data.error || 'Removal failed');
    return data;
  } catch (error) {
    throw error;
  }
}

export async function initiateKeygen(participants) {
  try {
    const response = await fetch(`${API_URL}/wallets`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ participants }),
    });
    const data = await response.json();
    if (!response.ok || !data.walletId) {
      throw new Error(data.error || 'Failed to initiate keygen session');
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function confirmWallet(walletId) {
  try {
    const response = await fetch(`${API_URL}/wallets/${walletId}/confirm`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.error || 'Confirmation failed');
    return data;
  } catch (error) {
    console.error('API Error (confirmWallet):', error.message);
    throw error;
  }
}

export async function receivePublicKey(sessionId, publicKey) {
  try {
    const response = await fetch(`${API_URL}/wallets/${sessionId}/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ publicKey }),
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.error || 'Failed to store public key');
    return data;
  } catch (error) {
    throw error;
  }
}

export async function fetchPendingWallets() {
  try {
    const response = await fetch(`${API_URL}/wallets/pending`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.error || 'Failed to fetch pending wallets');
    }
    return await response.json();
  } catch (error) {
    console.error('API Error (fetchPendingWallets):', error.message);
    throw error;
  }
}

export async function getSessionStatus(sessionId) {
  try {
    const response = await fetch(`${API_URL}/wallets/${sessionId}/status`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to fetch session status');
    }
    return await response.json();
  } catch (error) {
    console.error('Error in getSessionStatus:', error);
    throw error;
  }
}

export async function cancelWallet(walletId) {
  try {
    const response = await fetch(`${API_URL}/wallets/${walletId}/cancel`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.error || 'Failed to cancel wallet');
    return data;
  } catch (error) {
    throw error;
  }
}

export async function approveTransaction(walletId, transactionId) {
  try {
    const response = await fetch(
      `${API_URL}/wallets/${walletId}/transaction/${transactionId}/approve`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({}),
      }
    );
    const data = await response.json();
    if (!response.ok) throw new Error(data.error || 'Approval failed');
    return data;
  } catch (error) {
    throw error;
  }
}

export async function cancelTransaction(walletId, transactionId) {
  try {
    const response = await fetch(
      `${API_URL}/wallets/${walletId}/transaction/${transactionId}/cancel`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      }
    );
    const data = await response.json();
    if (!response.ok) throw new Error(data.error || 'Cancellation failed');
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getDisputeByWalletId(walletId) {
  try {
    const response = await fetch(`${API_URL}/disputes/wallet/${walletId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to fetch dispute details');
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
}
