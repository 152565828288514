// useAuthUser.js
import { useEffect, useState } from 'react';

const BASE_URL = process.env.REACT_APP_MAIN_SERVER_URL;

const useAuthUser = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${BASE_URL}/api/auth/user-info`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((res) => {
        if (!res.ok) {
          // If the response is not OK (e.g., 401 Unauthorized), set user to null
          setUser(null);
          setLoading(false);
          return null;
        }
        return res.json();
      })
      .then((data) => {
        if (data) {
          setUser(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching user info:', err);
        setUser(null);
        setLoading(false);
      });
  }, []);
  

  return { user, loading };
};

export default useAuthUser;
