import React, { useState } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const EmailPage = () => {
  const [recipient, setRecipient] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [error, setError] = useState('');
  const [sending, setSending] = useState(false);
  const navigate = useNavigate();

  const handleSendEmail = async () => {
    if (!recipient || !subject || !body) {
      setError('All fields are required!');
      return;
    }

    try {
      setSending(true);

      // Your API call for sending an email
      const response = await fetch('/api/send-email', { // Adjust the endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ recipient, subject, body }),
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      setRecipient('');
      setSubject('');
      setBody('');
      alert('Email sent successfully!');
      navigate('/employee'); // Navigate back after email is sent
    } catch (err) {
      setError(err.message || 'Error sending email');
    } finally {
      setSending(false);
    }
  };

  return (
    <div className="my-4">
      <h3>Email Interface</h3>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form>
        <Form.Group controlId="emailRecipient" className="mb-3">
          <Form.Label>Recipient</Form.Label>
          <Form.Control
            type="email"
            placeholder="Recipient's email address"
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="emailSubject" className="mb-3">
          <Form.Label>Subject</Form.Label>
          <Form.Control
            type="text"
            placeholder="Email Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="emailBody" className="mb-3">
          <Form.Label>Body</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder="Write your email here"
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />
        </Form.Group>

        <Button
          variant="primary"
          onClick={handleSendEmail}
          disabled={sending}
        >
          {sending ? 'Sending...' : 'Send Email'}
        </Button>
      </Form>
    </div>
  );
};

export default EmailPage;
