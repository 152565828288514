//Modals/CreateWalletModal.js

import React, { useState, useEffect } from 'react';
import { Modal, Form, Tab, Tabs, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

// Popup modal for entering additional participant emails.
// extraCount: number of email fields to display.
function ParticipantsModal({ show, onHide, participantEmails, setParticipantEmails, extraCount }) {
  const handleChange = (index, value) => {
    const newEmails = [...participantEmails];
    newEmails[index] = value;
    setParticipantEmails(newEmails);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Enter Participant Emails</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Array.from({ length: extraCount }, (_, index) => (
          <Form.Group key={index} controlId={`participantEmail-${index}`} className="mt-2">
            <Form.Label>Additional Participant {index + 1} Email</Form.Label>
            <Form.Control 
              type="email" 
              value={participantEmails[index] || ''} 
              onChange={(e) => handleChange(index, e.target.value)} 
              placeholder="Enter email address" 
            />
          </Form.Group>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={onHide}>
          Save Emails
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function CreateWalletModal({ show, handleClose, onWalletCreated, loggedInEmail }) {
  // Manage active tab.
  const [activeTab, setActiveTab] = useState('basic');
  // Basic tab fields.
  const [walletName, setWalletName] = useState('');
  const [coSigner, setCoSigner] = useState('');
  // Advanced tab fields.
  const [advancedWalletName, setAdvancedWalletName] = useState('');
  const [keyManagementOption, setKeyManagementOption] = useState('server'); // 'server' or 'external'
  
  // These dropdowns start out blank.
  const [totalParticipants, setTotalParticipants] = useState('');
  const [requiredSignatures, setRequiredSignatures] = useState('');
  
  // State for additional participant emails from the popup.
  const [participantEmails, setParticipantEmails] = useState([]);
  const [showParticipantsPopup, setShowParticipantsPopup] = useState(false);

  // Risk warning modal state.
  const [showRiskWarningModal, setShowRiskWarningModal] = useState(false);
  // Confirmation modal state.
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmationSummary, setConfirmationSummary] = useState(null);
  const [confirmData, setConfirmData] = useState(null);

  // Compute number of extra participant email fields:
  // For server-held key: extra = totalParticipants - 2 (logged-in user + server auto‑included).
  // For external: extra = totalParticipants - 1 (logged-in user auto‑included).
  const extraParticipantsCount =
    totalParticipants === ''
      ? 0
      : keyManagementOption === 'server'
      ? parseInt(totalParticipants, 10) - 2
      : parseInt(totalParticipants, 10) - 1;

  // Update participantEmails length when totalParticipants or keyManagementOption changes.
  useEffect(() => {
    if (totalParticipants === '') {
      setParticipantEmails([]);
    } else {
      setParticipantEmails(prev => {
        const newLength = extraParticipantsCount;
        const newEmails = [...prev];
        if (newEmails.length < newLength) {
          for (let i = newEmails.length; i < newLength; i++) {
            newEmails.push('');
          }
        } else if (newEmails.length > newLength) {
          newEmails.length = newLength;
        }
        return newEmails;
      });
    }
  }, [totalParticipants, keyManagementOption, extraParticipantsCount]);

  // Generate options for Total Wallet Participants.
  const getTotalParticipantsOptions = () => {
    const minTotal = keyManagementOption === 'server' ? 3 : 2;
    const options = [];
    for (let i = minTotal; i <= 10; i++) {
      options.push(i);
    }
    return options;
  };

  // Generate options for Required Signatures.
  // If the server key is selected, do not allow a configuration where all keys are required.
  const getRequiredSignaturesOptions = () => {
    const options = [];
    if (totalParticipants === '') return options;
    const tot = parseInt(totalParticipants, 10);
    // If server key is selected, allow only up to tot - 1.
    const max = keyManagementOption === 'server' ? tot - 1 : tot;
    for (let i = 2; i <= max; i++) {
      options.push(i);
    }
    return options;
  };

  // Build payload and a confirmation summary.
  // In advanced mode, if the server holds a key, it is placed at the top and wrapped in an OverlayTrigger with a dynamic tooltip.
  const buildPayloadAndSummary = () => {
    let finalWalletName = '';
    let participantsArray = [];
    let payload = {};
    let summary = null;
  
    if (activeTab === 'basic') {
      finalWalletName = walletName.trim();
      if (!finalWalletName) {
        alert("Please enter a wallet name.");
        return null;
      }
      // For basic mode, automatically include the server key.
      participantsArray = [{ email: 'Server (holds one key)' }, { email: loggedInEmail }];
      if (coSigner && coSigner.trim() !== '') {
        participantsArray.push({ email: coSigner.trim() });
      }
      // Ensure we have exactly 3 participants (server + loggedInUser + coSigner)
      if (participantsArray.length < 3) {
        alert('Please provide a co-signer email.');
        return null;
      }
      payload = {
        walletName: finalWalletName,
        participants: participantsArray,
      };
      summary = (
        <>
          <div><strong>Wallet Name:</strong> {finalWalletName}</div>
          <div><strong>Configuration:</strong> Basic Wallet (2 of 3)</div>
          <div><strong>Participants:</strong></div>
          <ul>
            {participantsArray.map((p, idx) => (
              <li key={idx}>{p.email}</li>
            ))}
          </ul>
        </>
      );
    } else if (activeTab === 'advanced') {
      // ...existing advanced mode code...
      finalWalletName = advancedWalletName.trim();
      if (!finalWalletName) {
        alert("Please provide a wallet name in the Advanced tab.");
        return null;
      }
      if (!totalParticipants || !requiredSignatures) {
        alert("Please select both Total Wallet Participants and Required Signatures.");
        return null;
      }
      if (participantEmails.length !== extraParticipantsCount) {
        alert("The number of additional participant emails does not match the Total Wallet Participants selected.");
        return null;
      }
      for (let i = 0; i < participantEmails.length; i++) {
        if (!participantEmails[i] || participantEmails[i].trim() === '') {
          alert(`Please enter an email for Additional Participant ${i + 1}.`);
          return null;
        }
      }
      // Build participants list.
      if (keyManagementOption === 'server') {
        participantsArray = [
          { email: 'Server (holds one key)' },
          { email: loggedInEmail }
        ];
      } else {
        participantsArray = [{ email: loggedInEmail }];
      }
      participantEmails.forEach(email => {
        participantsArray.push({ email: email.trim() });
      });
      if (participantsArray.length !== parseInt(totalParticipants, 10)) {
        alert("The number of participants does not match the Total Wallet Participants selected.");
        return null;
      }
      payload = {
        walletName: finalWalletName,
        participants: participantsArray,
        advancedOptions: {
          keyManagementOption,
          requiredSignatures: parseInt(requiredSignatures, 10),
          totalParticipants: parseInt(totalParticipants, 10),
        },
      };
      summary = (
        <>
          <div><strong>Wallet Name:</strong> {finalWalletName}</div>
          <div>
            <strong>Configuration:</strong> {requiredSignatures} of {totalParticipants} multisig wallet
          </div>
          <div><strong>Participants:</strong></div>
          <ul>
            {participantsArray.map((p, idx) => {
              if (p.email === 'Server (holds one key)') {
                const tooltipText = `Our secure server key is managed by professionals and provides a reliable backup. It only activates when at least ${requiredSignatures} approvals are reached, ensuring that the loss of a single user key does not lock you out.`;
                return (
                  <OverlayTrigger key={idx} placement="right" overlay={<Tooltip id="tooltip-server">{tooltipText}</Tooltip>}>
                    <li style={{ cursor: 'pointer' }}>{p.email}</li>
                  </OverlayTrigger>
                );
              } else {
                return <li key={idx}>{p.email}</li>;
              }
            })}
          </ul>
        </>
      );
    }
    return { payload, summary, participants: participantsArray };
  };
  
  // Update the risk warning logic so that basic mode doesn't trigger a warning.
  const shouldWarnRisk = (result) => {
    if (!result) return false;
    if (activeTab === 'basic') {
      // In basic mode, since we automatically include the server key,
      // the configuration is 2-of-3 (not 2-of-2), so we don't need to warn.
      return false;
    }
    if (activeTab === 'advanced' && parseInt(totalParticipants, 10) === parseInt(requiredSignatures, 10)) {
      return true;
    }
    return false;
  };

  // Final submission after confirmation.
  const submitWallet = async () => {
    if (!confirmData) return;
    try {
      const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/wallets`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(confirmData.payload),
      });
      
      if (!res.ok) throw new Error('Failed to create wallet');
      onWalletCreated && onWalletCreated();
      handleClose();
      // Reset fields.
      setWalletName('');
      setAdvancedWalletName('');
      setCoSigner('');
      setKeyManagementOption('server');
      setRequiredSignatures('');
      setTotalParticipants('');
      setParticipantEmails([]);
      setActiveTab('basic');
      setShowConfirmModal(false);
      setConfirmData(null);
      setConfirmationSummary(null);
      setShowRiskWarningModal(false);
    } catch (err) {
      console.error('Error creating wallet:', err);
      alert(err.message);
    }
  };

  // Form submission handler: validate and then show risk warning (if needed) or confirmation modal.
  const handleSubmit = (e) => {
    e.preventDefault();
    const result = buildPayloadAndSummary();
    if (!result) return;
    // If configuration requires every key, show risk warning.
    if (shouldWarnRisk(result)) {
      setConfirmData(result);
      setConfirmationSummary(result.summary);
      setShowRiskWarningModal(true);
    } else {
      setConfirmData(result);
      setConfirmationSummary(result.summary);
      setShowConfirmModal(true);
    }
  };

  // Handler when user confirms the risk warning.
  const handleRiskWarningContinue = () => {
    setShowRiskWarningModal(false);
    setShowConfirmModal(true);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Multisig Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} id="create-wallet-tabs">
              <Tab eventKey="basic" title="Basic">
                <Form.Group controlId="walletNameBasic" className="mt-3">
                  <Form.Label>Wallet Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={walletName}
                    onChange={(e) => setWalletName(e.target.value)}
                    placeholder="Enter wallet name"
                  />
                </Form.Group>
                <Form.Group controlId="coSigner" className="mt-3">
                  <Form.Label>Co-Signer Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={coSigner}
                    onChange={(e) => setCoSigner(e.target.value)}
                    placeholder="Enter co-signer's email"
                  />
                </Form.Group>
              </Tab>
              <Tab eventKey="advanced" title="Advanced">
                <Form.Group controlId="advancedWalletName" className="mt-3">
                  <Form.Label>Wallet Name (Required)</Form.Label>
                  <Form.Control
                    type="text"
                    value={advancedWalletName}
                    onChange={(e) => setAdvancedWalletName(e.target.value)}
                    placeholder="Enter wallet name for advanced configuration"
                  />
                </Form.Group>
                <Form.Group controlId="keyManagement" className="mt-3">
                  <Form.Label>Key Management</Form.Label>
                  <div>
                    <Form.Check
                      inline
                      label="Server holds one key"
                      name="keyManagement"
                      type="radio"
                      id="server-key"
                      checked={keyManagementOption === 'server'}
                      onChange={() => {
                        setKeyManagementOption('server');
                        if (totalParticipants !== '' && parseInt(totalParticipants, 10) < 3) {
                          setTotalParticipants('3');
                        }
                        if (requiredSignatures !== '' && parseInt(requiredSignatures, 10) < 2) {
                          setRequiredSignatures('2');
                        }
                      }}
                    />
                    <Form.Check
                      inline
                      label="User selects all keys"
                      name="keyManagement"
                      type="radio"
                      id="external-key"
                      checked={keyManagementOption === 'external'}
                      onChange={() => setKeyManagementOption('external')}
                    />
                  </div>
                </Form.Group>
                <Form.Group controlId="totalParticipants" className="mt-3">
                  <Form.Label>Total Wallet Participants</Form.Label>
                  <Form.Control
                    as="select"
                    value={totalParticipants}
                    onChange={(e) => setTotalParticipants(e.target.value)}
                  >
                    <option value="">Select Total Wallet Participants</option>
                    {getTotalParticipantsOptions().map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="requiredSignatures" className="mt-3">
                  <Form.Label>Required Signatures</Form.Label>
                  <Form.Control
                    as="select"
                    value={requiredSignatures}
                    onChange={(e) => setRequiredSignatures(e.target.value)}
                    disabled={totalParticipants === ''}
                  >
                    <option value="">Select Required Signatures</option>
                    {getRequiredSignaturesOptions().map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <div className="mt-3">
                  <Button 
                    variant="secondary" 
                    onClick={() => setShowParticipantsPopup(true)}
                    disabled={totalParticipants === '' || requiredSignatures === ''}
                  >
                    Enter Participant Emails
                  </Button>
                </div>
              </Tab>
            </Tabs>
            <Button variant="primary" type="submit" className="mt-3">
              Create Wallet
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <ParticipantsModal 
        show={showParticipantsPopup} 
        onHide={() => setShowParticipantsPopup(false)} 
        participantEmails={participantEmails} 
        setParticipantEmails={setParticipantEmails}
        extraCount={extraParticipantsCount}
      />
      {/* Risk Warning Modal */}
      <Modal show={showRiskWarningModal} onHide={() => setShowRiskWarningModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Warning: All Keys Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {activeTab === 'basic' ? (
            <div>
              You are about to create a 2-of-2 wallet. This configuration requires every private key to approve transactions.
              <br /><br />
              <strong>Important:</strong> While a server key can offer a secure backup when not every key is required, in a 2-of-2 setup every key is mandatory. Losing any key may result in permanent loss of funds.
              <br /><br />
              Consider leveraging our server key in a configuration that requires fewer than all keys for added protection.
            </div>
          ) : (
            <div>
              You are about to create a {requiredSignatures} of {totalParticipants} multisig wallet, meaning all {totalParticipants} keys are required.
              <br /><br />
              <strong>Risk Notice:</strong> When every key is required, the loss of any single private key can prevent transactions from being approved.
              <br /><br />
              <strong>Why Use a Server Key?</strong> Our secure server key is managed by professionals and acts as a reliable backup. For instance, if you selected a 3-of-3 configuration, you could instead configure a 3-of-4 multisig wallet (adding one extra server key) to reduce the risk of losing access.
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRiskWarningModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleRiskWarningContinue}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Confirmation Modal */}
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Wallet Creation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {confirmationSummary}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={submitWallet}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
