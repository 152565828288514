// Modals/WalletDetailModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../pages/dashboard.css';

function WalletDetailModal({ show, handleClose, walletDetails, onCopy }) {
  if (!walletDetails) return null;

  // Combine public keys from participants and the server.
  const publicKeys = [
    ...walletDetails.participants.map((p) => p.publicKey),
    walletDetails.serverPublicKey,
  ].filter(Boolean);

  return (
    <Modal 
      show={show} 
      onHide={handleClose} 
      size="lg" 
      centered
      dialogClassName="modal-dialog-centered"
    >
      <Modal.Header className="bg-gray-800 text-white rounded-t">
        <Modal.Title>Wallet Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-gray-800 text-white">
        {walletDetails.multisigAddress && (
          <div className="mb-4">
            <strong>Multisig Address:</strong>
            <div className="mt-2 p-3 bg-gray-700 rounded font-mono text-lg break-words">
              {walletDetails.multisigAddress}
            </div>
            <Button
              variant="outline-light"
              size="sm"
              onClick={() => onCopy(walletDetails.multisigAddress)}
              className="mt-2"
            >
              Copy Address
            </Button>
          </div>
        )}
        <div>
          <strong>All Public Keys:</strong>
          <div className="mt-2 p-3 bg-gray-700 rounded font-mono text-base break-words">
            {publicKeys.map((key, idx) => (
              <div key={idx}>{key}</div>
            ))}
          </div>
          <Button
            variant="outline-light"
            size="sm"
            onClick={() => onCopy(publicKeys.join('\n'))}
            className="mt-2"
          >
            Copy All Public Keys
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-gray-800 rounded-b">
        <Button variant="outline-light" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default WalletDetailModal;
