// src/multisig/Routes.js
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import UpdateUserInfo from '../UpdateUserInfo';
import DisputeDetails from '../pages/DisputeDetails';
import MediationHub from '../pages/MediationHub'; // For mediator dashboard
import MediatorOptIn from '../MediatorOptIn'; // For mediator opt-in (become mediator)

const MultisigRoutes = () => {
  return (
    <Routes>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="updateInfo" element={<UpdateUserInfo />} />
      <Route path="disputes/:walletId" element={<DisputeDetails />} />
      <Route path="mediation-hub" element={<MediationHub />} />
      {/* New route for mediator opt-in */}
      <Route path="become-mediator" element={<MediatorOptIn />} />
      <Route path="*" element={<Navigate to="dashboard" replace />} />
    </Routes>
  );
};

export default MultisigRoutes;
