import React, { useState } from 'react';
import { useAuth } from './AuthContext';

const MediatorOptIn = () => {
  const { setUser } = useAuth();
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);
  const [hidden, setHidden] = useState(false);

  const handleOptIn = async () => {
    setFeedback('');
    setLoading(true);
    try {
      const response = await fetch('{process.env.REACT_APP_MAIN_API_URL}/api/auth/become-mediator', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to update mediator status');
      }
      // Update the user context with the updated mediator status.
      setUser(data.user);
      setFeedback('You are now registered as a mediator.');
      // Hide the mediator opt-in after a delay.
      setTimeout(() => setHidden(true), 3000);
    } catch (error) {
      setFeedback(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (hidden) return null;

  return (
    <div className="login-card mt-4 p-4 bg-gray-700 rounded-lg">
      <h4 className="text-xl font-semibold text-white mb-2">Become a Mediator</h4>
      <p className="text-white mb-2">
        Based on your transaction history, <br />
        you qualify to serve as a mediator. <br />
        Would you like to opt in?
      </p>
      <button className="btn btn-primary w-full" onClick={handleOptIn} disabled={loading}>
        {loading ? 'Processing...' : 'Yes, I want to be a mediator'}
      </button>
      {feedback && <p className="text-white mt-2 text-center">{feedback}</p>}
    </div>
  );
};

export default MediatorOptIn;
