//src/Modals/KycForm.js

import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

export default function KycForm({ show, handleClose }) {
  const [fullName, setFullName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [address, setAddress] = useState('');
  const [consentToVerify, setConsentToVerify] = useState(false);
  const [idDocument, setIdDocument] = useState(null);
  const [selfie, setSelfie] = useState(null);

  async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append('fullName', fullName);
    formData.append('dateOfBirth', dateOfBirth);
    formData.append('address', address);
    formData.append('consentToVerify', consentToVerify);
    if (idDocument) formData.append('idDocument', idDocument);
    if (selfie) formData.append('selfie', selfie);

    try {
      const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/api/kyc/submit`, {

        method: 'POST',
        // Let the browser set the Content-Type with the boundary automatically.
        credentials: 'include', // Include cookies for authentication
        body: formData,
      });
      const data = await res.json();
      if (!res.ok) throw new Error(data.error || 'KYC submission failed');
      alert(data.message);
      handleClose();
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Submit KYC Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="fullName">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your full name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="dateOfBirth" className="mt-3">
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control
              type="date"
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="address" className="mt-3">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="consentToVerify" className="mt-3">
            <Form.Check
              type="checkbox"
              label="I consent to verify my identity"
              checked={consentToVerify}
              onChange={(e) => setConsentToVerify(e.target.checked)}
            />
          </Form.Group>
          <Form.Group controlId="idDocument" className="mt-3">
            <Form.Label>Upload ID Document (JPEG, PNG, PDF)</Form.Label>
            <Form.Control
              type="file"
              accept="image/jpeg, image/jpg, image/png, application/pdf"
              onChange={(e) => setIdDocument(e.target.files[0])}
              required
            />
          </Form.Group>
          <Form.Group controlId="selfie" className="mt-3">
            <Form.Label>Upload Selfie (JPEG, PNG)</Form.Label>
            <Form.Control
              type="file"
              accept="image/jpeg, image/jpg, image/png"
              onChange={(e) => setSelfie(e.target.files[0])}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3">
            Submit KYC
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
