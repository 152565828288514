//hooks/useWallets.js

import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { io } from 'socket.io-client';

const socket = io(process.env.REACT_APP_MAIN_SERVER_URL, {
  withCredentials: true,  // Ensure cookies are sent with the Socket.IO connection
});
const API_URL = process.env.REACT_APP_MAIN_API_URL;


export default function useWallets(loggedInEmail) {
  const [wallets, setWallets] = useState([]);
  const fetchTimeoutRef = useRef(null);

  const fetchWallets = useCallback(async () => {
    try {
      const res = await fetch(`${API_URL}/wallets`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Send the auth cookie
      });
      
      if (!res.ok) {
        const errorText = await res.text();
        throw new Error(`Failed to fetch wallets: ${res.status} ${res.statusText} - ${errorText}`);
      }
      
      const data = await res.json();

      // For each wallet, only fetch its balance if it is active.
      const walletsWithBalance = await Promise.all(
        data.map(async (wallet) => {
          if (wallet.status === 'active') {
            try {
              const balanceRes = await fetch(`${API_URL}/wallets/${wallet._id}/balance`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
                credentials: 'include',
              });
              if (!balanceRes.ok) throw new Error('Failed to fetch balance');
              const balanceData = await balanceRes.json();
              return { ...wallet, balance: balanceData.balance };
            } catch (err) {
              console.error(err);
              return { ...wallet, balance: 0 };
            }
          } else {
            // For non-active wallets, use existing balance or 0.
            return { ...wallet, balance: wallet.balance || 0 };
          }
        })
      );
      setWallets(walletsWithBalance);
    } catch (err) {
      console.error('[useWallets] fetchWallets error:', err);
    }
  }, []);

  useEffect(() => {
    // Initial fetch on mount
    fetchWallets();

    socket.on('walletEvent', (data) => {
      console.log('[useWallets] walletEvent:', data);
      if (data.walletId && data.status) {
        setWallets((prev) =>
          prev.map((w) =>
            w._id === data.walletId ? { ...w, status: data.status } : w
          )
        );
      }
    });

    socket.on('transactionEvent', (data) => {
      console.log('[useWallets] transactionEvent:', data);
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
      }
      fetchTimeoutRef.current = setTimeout(() => {
        fetchWallets();
        fetchTimeoutRef.current = null;
      }, 2000); // Debounce delay (adjust as needed)
    });
    
    return () => {
      socket.off('walletEvent');
      socket.off('transactionEvent');
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
      }
    };
  }, [fetchWallets]);

  // Derived values using useMemo
  const activeWallets = useMemo(() => wallets.filter(w => w.status === 'active'), [wallets]);
  const pendingWallets = useMemo(() => wallets.filter(w => w.status === 'pending'), [wallets]);
  const closedWallets = useMemo(() => wallets.filter(w => w.status === 'closed'), [wallets]);
  const disputedWallets = useMemo(() => wallets.filter(w => w.status === 'disputed'), [wallets]);
  const activeAndPendingWallets = useMemo(
    () => wallets.filter(w => w.status === 'active' || w.status === 'pending'),
    [wallets]
  );
  const totalBalance = useMemo(
    () => wallets.reduce((sum, w) => sum + (w.balance || 0), 0),
    [wallets]
  );
  const pendingApprovalCount = useMemo(() => pendingWallets.length, [pendingWallets]);
  const recentTxCount = useMemo(() => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    return wallets.reduce((count, w) => {
      if (!w.transactions) return count;
      return count + w.transactions.filter(tx => new Date(tx.createdAt) >= sevenDaysAgo).length;
    }, 0);
  }, [wallets]);
  const pendingDisputesCount = useMemo(() => disputedWallets.length, [disputedWallets]);

  return { 
    wallets, 
    fetchWallets,
    activeWallets,
    pendingWallets,
    closedWallets,
    disputedWallets,
    activeAndPendingWallets,
    totalBalance,
    pendingApprovalCount,
    recentTxCount,
    pendingDisputesCount
  };

  
}
