// src/pages/Login.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { loginUser, getUserInfo } from '../api';
import '../App.css';
import api from '../apiClient';

const OTP_DURATION = 120; // OTP valid for 120 seconds (2 minutes)

const Login = () => {
  const { setAuthenticated, setUser } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [show2faModal, setShow2faModal] = useState(false);
  const [otpTimer, setOtpTimer] = useState(0);
  const navigate = useNavigate();
 



  // Security-enhanced tab title with connection check
  useEffect(() => {
    const isSecureConnection = window.location.protocol === 'https:';
    document.title = isSecureConnection 
      ? "Multisig Vault | Secure Login" 
      : "Connection Not Secure";
  }, []);

  

  // Countdown timer for OTP
  useEffect(() => {
    let timer;
    if (show2faModal && otpTimer > 0) {
      timer = setInterval(() => {
        setOtpTimer((prev) => prev - 1);
      }, 1000);
    }
    if (otpTimer === 0 && show2faModal) {
      setMessage("OTP time expired. Please log in again.");
      setShow2faModal(false);
      setOtp('');
    }
    return () => clearInterval(timer);
  }, [show2faModal, otpTimer]);

  // Handles email/password login and issues a temporary token
  const handleLogin = async (event) => {
    event.preventDefault();
    setMessage("");
    if (!email.trim() || !password.trim()) {
      setMessage("Please fill out all fields.");
      return;
    }
    try {
      await loginUser(email, password);
      // Once login is successful, automatically send OTP and show the 2FA modal
      setShow2faModal(true);
      setOtpTimer(OTP_DURATION);
      await sendOtp();
    } catch (error) {
      setMessage(error.message || "Login failed. Please check your credentials.");
    }
  };

  // Requests an OTP to be sent to the provided email automatically
  const sendOtp = async () => {
    setMessage("");
    try {
      const response = await api.post('/auth/send-email-otp', { email });
      if (response.data.message) {
        setMessage("OTP sent to your email. Please enter it below.");
      }
    } catch (error) {
      setMessage("Failed to send OTP via email: " + error.message);
      console.error("sendOtp error:", error);
    }
  };

  // Verifies the OTP and, if successful, updates the token to mark 2FA as complete
  const verifyOtp = async (event) => {
    if (event) event.preventDefault();
    setMessage("");
    if (!otp) {
      setMessage("Please enter the OTP.");
      return;
    }
    try {
      const response = await api.post('/auth/verify-email-otp', { email, otp });
      if (response.data.message !== 'OTP verified, token updated') {
        throw new Error('OTP verification failed');
      }
      setMessage("OTP verified! Logging in...");
      // Re-fetch user info after the final token is issued
      const userInfo = await getUserInfo(email);
      setUser(userInfo);
      setAuthenticated(true);
      if (userInfo.role === "employee") {
        navigate("/employee");
      } else {
        navigate("/multisig");
      }
      setShow2faModal(false);
    } catch (error) {
      setMessage("Invalid OTP. Please try again.");
      console.error("verifyOtp error:", error);
    }
  };
  
  useEffect(() => {
    if (!show2faModal) {
      setMessage("");
    }
  }, [show2faModal]);
  

  return (
    <div className="login-container">
      {!show2faModal ? (
        <div className="login-card sleek-tech">
<form onSubmit={handleLogin}>
  <input
    type="email"
    placeholder="Email Address"
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    required
  />
  <input
    type="password"
    placeholder="Password"
    value={password}
    onChange={(e) => setPassword(e.target.value)}
    required
  />

  {/* 🔥 Error message displayed above the submit button */}
  {message && (
    <p
      className={`message ${
        message.includes('failed') ||
        message.includes('Invalid') ||
        message.includes('Please') ||
        message.includes('Missing')
          ? 'error'
          : 'success'
      }`}
      style={{ marginTop: '10px', marginBottom: '10px' }}
    >
      {message}
    </p>
  )}

  <div className="forgot-link-container">
    <Link to="/forgot-password" className="forgot-link">
      Need to reset password?
    </Link>
  </div>
  <button type="submit" className="mb-6">Login</button>
</form>

          <p>
            New to secure transactions?{' '}
            <Link to="/signup" className="signup-link">Sign up now</Link>
          </p>
        </div>
      ) : (
        <div className="otp-container">
          <div className="otp-content sleek-tech">
            <h3>Two-Factor Authentication</h3>
            {/* Reserve a fixed area for the message to avoid layout shifts */}
            <div className="otp-message">
              <p className={`message ${message.includes('failed') || message.includes('Error') ? 'error' : 'success'}`}>
                {message || "\u00A0"}
              </p>
            </div>
            <p className="timer">Time remaining: {otpTimer} seconds</p>
            <form onSubmit={verifyOtp}>
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
                className="otp-input"
              />
              <button className="mb-6 btn-wide" style={{ width: '170px' }} type="submit">
                Verify OTP
              </button>
              
            </form>
            <button
              className="mb-6 btn-wide"
              style={{ width: '170px' }}
              onClick={() => {
                setShow2faModal(false);
                setOtp('');
                setMessage("");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
