// src/Modals/CreateTransactionModal.js
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const CreateTransactionModal = ({ show, handleClose, onSubmit }) => {
  const [toAddress, setToAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    if (!toAddress || !amount) {
      setError('Please fill in all fields.');
      return;
    }
    // Optionally validate that amount is a positive number
    if (isNaN(amount) || parseFloat(amount) <= 0) {
      setError('Amount must be a positive number.');
      return;
    }
    // Call the onSubmit function passed via props.
    await onSubmit({ toAddress, amount: parseFloat(amount) });
    // Clear the form fields after successful submission
    setToAddress('');
    setAmount('');
  };

  return (
    <Modal show={show} onHide={handleClose} centered dialogClassName="modal-dialog-centered">
      <Modal.Header closeButton className="bg-gray-800 text-white">
        <Modal.Title>Create Transaction</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-gray-800 text-white">
        <Form onSubmit={handleSubmit} className="flex flex-col space-y-4">
          <Form.Group controlId="toAddress">
            <Form.Label>Destination Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter destination BTC address"
              value={toAddress}
              onChange={(e) => setToAddress(e.target.value)}
              className="form-input"
              required
            />
          </Form.Group>
          <Form.Group controlId="amount">
            <Form.Label>Amount (BTC)</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="form-input"
              required
            />
          </Form.Group>
          {error && <p className="text-red-400">{error}</p>}
          <Button variant="primary" type="submit" className="btn btn-primary w-full">
            Create Transaction
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer className="bg-gray-800">
        <Button variant="outline-light" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateTransactionModal;
