// src/Modals/SessionExpiredModal.js
import React from 'react';

const SessionExpiredModal = ({ onOk }) => {
  return (
    <div style={modalOverlayStyle}>
      <div style={modalContentStyle}>
        <h2>Session Expired</h2>
        <p>Your session has expired because you logged in on another device. Please log in again.</p>
        <button onClick={onOk}>OK</button>
      </div>
    </div>
  );
};

const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0,0,0,0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000
};

const modalContentStyle = {
  background: '#fff',
  padding: '20px',
  borderRadius: '4px',
  textAlign: 'center',
  maxWidth: '400px',
  width: '100%'
};

export default SessionExpiredModal;
