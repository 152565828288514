//components/WalletList.js

import React, { useState, useEffect } from 'react';
import { Card, Button, Alert, ListGroup, Badge } from 'react-bootstrap';
import WalletDetailModal from '../Modals/WalletDetailModal';
import CreateTransactionModal from '../Modals/CreateTransactionModal';
import { useAuth } from '../AuthContext';
import '../pages/dashboard.css';

export default function WalletList({
  wallets,
  loggedInEmail,
  isGeneratingKeys,
  onCloseWallet,
  onDisputeWallet,
  onGenerateKeys,
  onCancelWallet,
  onCancelTransaction,
  onRemoveTransaction,
  onApproveTransaction,
  onViewDispute,
}) {
  const { setUser } = useAuth();

  // State for wallet details modal.
  const [detailsWallet, setDetailsWallet] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  // Cancel notifications.
  const [cancelNotifications, setCancelNotifications] = useState([]);
  const [acknowledgedTxs, setAcknowledgedTxs] = useState(() => {
    const stored = localStorage.getItem('acknowledgedTxs');
    return stored ? JSON.parse(stored) : [];
  });
  // Track which closed wallets are expanded.
  const [expandedWallets, setExpandedWallets] = useState({});

  // State for transaction modal.
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [selectedWalletId, setSelectedWalletId] = useState(null);

  // Sort wallets so that pending wallets come first.
  const sortedWallets = wallets.slice().sort((a, b) => {
    if (a.status === 'pending' && b.status !== 'pending') return -1;
    if (a.status !== 'pending' && b.status === 'pending') return 1;
    return 0;
  });

  // useEffect: Scan wallets for cancelled transactions requiring acknowledgement.
  useEffect(() => {
    const notifications = [];
    const userEmail = loggedInEmail.toLowerCase();
    wallets.forEach((wallet) => {
      if (wallet.transactions && wallet.transactions.length) {
        wallet.transactions.forEach((tx) => {
          if (
            (tx.status === 'cancelled' || tx.status === 'cancelledAfterApproval') &&
            (!tx.cancelAcknowledgements ||
              !tx.cancelAcknowledgements.map((e) => e.toLowerCase()).includes(userEmail)) &&
            tx.cancelledBy.toLowerCase() !== userEmail
          ) {
            notifications.push({
              walletId: wallet._id,
              txId: tx._id,
              cancelledBy: tx.cancelledBy,
              message: `Transaction ${tx._id} was canceled by ${tx.cancelledBy}. Please acknowledge.`,
            });
          }
        });
      }
    });
    if (JSON.stringify(notifications) !== JSON.stringify(cancelNotifications)) {
      setCancelNotifications(notifications);
    }
  }, [wallets, loggedInEmail, cancelNotifications]);

  // Copy text to clipboard.
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => alert('Copied to clipboard!'))
      .catch((err) => console.error('Copy failed:', err));
  };

  // Show wallet details modal.
  const handleShowDetails = async (wallet) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/wallets/${wallet._id}`, {
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Failed to fetch wallet details');
      const walletDetails = await response.json();
      setDetailsWallet(walletDetails);
      setShowDetailsModal(true);
    } catch (error) {
      console.error('Error fetching wallet details:', error);
      alert('Error fetching wallet details.');
    }
  };

  // When user clicks "Start Tx", show transaction modal.
  const openTransactionModal = (walletId) => {
    setSelectedWalletId(walletId);
    setShowTransactionModal(true);
  };

  // Mark transaction complete.
  const markTransactionComplete = async (walletId) => {
    try {
      const res = await fetch('{process.env.REACT_APP_MAIN_API_URL}/transactions/complete', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ walletId }),
      });
      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.error || 'Failed to update transaction status');
      }
      const data = await res.json();
      setUser(data.user);
      console.log('User updated with transaction complete:', data.user);
    } catch (error) {
      console.error('Error marking transaction complete:', error);
    }
  };

  // Handle transaction creation.
  const handleCreateTransaction = async ({ toAddress, amount }) => {
    if (!selectedWalletId) {
      console.error('No wallet selected for the transaction.');
      return;
    }
    try {
      const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/wallets/${selectedWalletId}/transaction`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ toAddress, amount }),
      });
      if (!res.ok) throw new Error('Failed to create transaction');
      const result = await res.json();
      console.log('Transaction created:', result);
      setShowTransactionModal(false);
      await markTransactionComplete(selectedWalletId);
    } catch (error) {
      console.error('Error creating transaction:', error);
    }
  };

  // Check if user already approved a transaction.
  const hasUserApproved = (tx) => {
    return (
      tx.approvals &&
      tx.approvals.some(
        (approval) => approval.email.toLowerCase() === loggedInEmail.toLowerCase()
      )
    );
  };

  // Handle transaction cancellation.
  const handleTxCancel = async (walletId, txId) => {
    try {
      const res = await onCancelTransaction(walletId, txId);
      if (res && res.cancelledBy && res.cancelledBy.toLowerCase() !== loggedInEmail.toLowerCase()) {
        setCancelNotifications((prev) => [
          ...prev,
          {
            walletId,
            txId,
            cancelledBy: res.cancelledBy,
            message: `Transaction ${txId} was canceled by ${res.cancelledBy}. Please acknowledge.`,
          },
        ]);
      }
    } catch (err) {
      console.error('Cancellation error:', err);
    }
  };

  // Acknowledge cancellation notification.
  const handleAcknowledgeCancel = async (walletId, txId) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_MAIN_API_URL}/wallets/${walletId}/transaction/${txId}/acknowledge-cancellation`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });
      if (!res.ok) {
        const data = await res.json();
        throw new Error(data.error || 'Failed to acknowledge cancellation');
      }
      const updatedAck = [...acknowledgedTxs, txId];
      setAcknowledgedTxs(updatedAck);
      localStorage.setItem('acknowledgedTxs', JSON.stringify(updatedAck));
      setCancelNotifications((prev) => prev.filter((n) => n.txId !== txId));
    } catch (err) {
      console.error('Acknowledge cancellation error:', err);
      alert(err.message);
    }
  };

  // Toggle expansion for a closed wallet.
  const toggleWalletExpansion = (walletId) => {
    setExpandedWallets((prev) => ({
      ...prev,
      [walletId]: !prev[walletId],
    }));
  };

  return (
    <>
      {cancelNotifications.map((notif) => (
        <Alert
          key={notif.txId}
          variant="warning"
          dismissible={false}
          className="dark-alert"
          style={{ marginBottom: '1rem' }}
        >
          {notif.message}
          <Button
            variant="outline-light"
            size="sm"
            onClick={() => handleAcknowledgeCancel(notif.walletId, notif.txId)}
            style={{ marginLeft: '1rem' }}
          >
            Acknowledge
          </Button>
        </Alert>
      ))}

      <div className="wallet-cards">
        {sortedWallets.length === 0 ? (
          <p>No wallets</p>
        ) : (
          sortedWallets.map((w) => {
            // Determine actions based on wallet status.
            let actions = null;
            if (w.status === 'active') {
              actions = (
                <>
                  <Button variant="info" onClick={() => handleShowDetails(w)} className="mr-2">
                    Details
                  </Button>
                  <Button variant="warning" onClick={() => onCloseWallet && onCloseWallet(w._id)} className="mr-2">
                    Close
                  </Button>
                  <Button variant="success" onClick={() => openTransactionModal(w._id)} className="mr-2">
                    Start Tx
                  </Button>
                  <Button variant="danger" onClick={() => onDisputeWallet && onDisputeWallet(w._id)}>
                    Dispute
                  </Button>
                </>
              );
            } else if (w.status === 'pending') {
              actions = (
                <>
                  <Button variant="primary" onClick={() => onGenerateKeys && onGenerateKeys(w._id)} disabled={isGeneratingKeys} className="mr-2">
                    Generate Keys
                  </Button>
                  <Button variant="secondary" onClick={() => onCancelWallet && onCancelWallet(w._id)}>
                    Cancel
                  </Button>
                </>
              );
            } else if (w.status === 'disputed') {
              actions = (
                <Button variant="info" onClick={() => onViewDispute && onViewDispute(w._id)}>
                  View Dispute
                </Button>
              );
            } else if (w.status === 'closed') {
              actions = null; // Closed wallets use expansion.
            }

            const pendingTxs =
              w.transactions && w.transactions.filter((tx) => tx.status === 'pending' && !acknowledgedTxs.includes(tx._id));
            const completedTxs =
              w.transactions && w.transactions.filter((tx) => tx.status === 'approved');

            return (
              <Card key={w._id} bg="dark" text="light" className="mb-3">
                <Card.Header>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <span>{w.walletName}</span>
                      <Badge variant={w.status === 'pending' ? 'warning' : (w.status === 'active' ? 'success' : 'secondary')} style={{ marginLeft: '0.5rem' }}>
                        {w.status}
                      </Badge>
                    </div>
                    {w.status === 'closed' && (
                      <span onClick={() => toggleWalletExpansion(w._id)} style={{ cursor: 'pointer', color: '#aaa' }}>
                        {expandedWallets[w._id] ? '▲' : '▼'}
                      </span>
                    )}
                  </div>
                </Card.Header>
                <Card.Body>
                  {w.status !== 'pending' && (
                    <Card.Text>
                      <strong>Balance:</strong> {(w.balance || 0).toFixed(4)} BTC
                    </Card.Text>
                  )}
                  <Card.Text>
                    <strong>Participants:</strong> {w.participants.map((p) => p.email).join(', ')}
                  </Card.Text>
                  <div className="wallet-actions">
                    {actions}
                  </div>
                </Card.Body>
                {w.status === 'active' && pendingTxs && pendingTxs.length > 0 && (
                  <Card.Footer>
                    <h6>Pending Transactions:</h6>
                    <ListGroup variant="flush">
                      {pendingTxs.map((tx) => (
                        <ListGroup.Item key={tx._id} variant="dark">
                          <div>
                            <strong>To:</strong> {tx.toAddress} | <strong>Amount:</strong> {tx.amount} BTC
                          </div>
                          <div>
                            <small>{new Date(tx.createdAt).toLocaleString()}</small>
                          </div>
                          <div className="mt-2">
                            <Button
                              variant={hasUserApproved(tx) ? 'secondary' : 'success'}
                              size="sm"
                              onClick={() =>
                                !hasUserApproved(tx) &&
                                onApproveTransaction &&
                                onApproveTransaction(w._id, tx._id)
                              }
                              disabled={hasUserApproved(tx)}
                              className="mr-2"
                            >
                              {hasUserApproved(tx) ? 'Approved' : 'Approve'}
                            </Button>
                            <Button variant="danger" size="sm" onClick={() => handleTxCancel(w._id, tx._id)}>
                              Cancel
                            </Button>
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Card.Footer>
                )}
                {w.status === 'closed' && expandedWallets[w._id] && (
                  <Card.Footer style={{ backgroundColor: '#222' }}>
                    <h6>Completed Transactions:</h6>
                    {completedTxs && completedTxs.length > 0 ? (
                      <ListGroup variant="flush">
                        {completedTxs.map((tx) => (
                          <ListGroup.Item key={tx._id} variant="dark">
                            <div>
                              <strong>To:</strong> {tx.toAddress} | <strong>Amount:</strong> {tx.amount} BTC
                            </div>
                            <div>
                              <small>{new Date(tx.createdAt).toLocaleString()}</small>
                            </div>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    ) : (
                      <p>No completed transactions.</p>
                    )}
                  </Card.Footer>
                )}
              </Card>
            );
          })
        )}
      </div>

      {/* Wallet Details Modal */}
      <WalletDetailModal
        show={showDetailsModal}
        handleClose={() => {
          setShowDetailsModal(false);
          setDetailsWallet(null);
        }}
        walletDetails={detailsWallet}
        onCopy={copyToClipboard}
      />

      {/* Create Transaction Modal */}
      <CreateTransactionModal
        show={showTransactionModal}
        handleClose={() => setShowTransactionModal(false)}
        onSubmit={handleCreateTransaction}
      />
    </>
  );
}
