import React, { useState, useEffect } from 'react';
import { getUserInfo, updateUserInfo } from './api';
import { useAuth } from './AuthContext';
import './pages/dashboard.css';

const UpdateUserInfo = ({ onStyleUpdate }) => {
  const { user, setUser } = useAuth();
  const [formData, setFormData] = useState({
    email: '',
    newPassword: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    stateVal: '',
    country: '',
    phoneNumber: '',
    testMode: false,
  });
  const [message, setMessage] = useState('');
  

  const {
    email,
    newPassword,
    confirmPassword,
    firstName,
    lastName,
    address,
    city,
    stateVal,
    country,
    phoneNumber,
    testMode,
  } = formData;

  // Fetch user info on mount.
  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!user?.email) {
        setMessage('User is not logged in');
        return;
      }
      try {
        const data = await getUserInfo(user.email);
        setFormData({
          email: data.email || '',
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          address: data.address || '',
          city: data.city || '',
          stateVal: data.state || '',
          country: data.country || '',
          phoneNumber: data.phoneNumber || '',
          newPassword: '',
          confirmPassword: '',
          testMode: data.networkType === 'regtest',
        });
      } catch (error) {
        setMessage('Error fetching user info.');
      }
    };
    fetchUserInfo();
  }, [user]);

  // Handle changes for all inputs.
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Handle immediate toggle of test mode.
  const handleTestModeChange = async (e) => {
    handleInputChange(e);
    const isTestMode = e.target.checked;
    const newNetworkType = isTestMode ? 'regtest' : 'mainnet';
    try {
      await updateUserInfo(email, { networkType: newNetworkType });
      if (user) {
        setUser({ ...user, networkType: newNetworkType });
      }
    } catch (error) {
      console.error('Failed to update network type:', error);
      setFormData((prev) => ({
        ...prev,
        testMode: !isTestMode,
      }));
    }
  };

  // Handle form submission.
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!user) {
      setMessage('Unauthorized: Please log in again.');
      return;
    }
    if (
      !email.trim() ||
      !firstName.trim() ||
      !lastName.trim() ||
      !address.trim() ||
      !city.trim() ||
      !stateVal.trim() ||
      !country.trim() ||
      !phoneNumber.trim()
    ) {
      setMessage('Please fill out all required fields.');
      return;
    }
    if (newPassword && newPassword !== confirmPassword) {
      setMessage('New password and confirmation do not match.');
      return;
    }
    try {
      const networkType = testMode ? 'regtest' : 'mainnet';
      const updatedData = {
        firstName,
        lastName,
        email,
        address,
        city,
        state: stateVal,
        country,
        phoneNumber,
        networkType,
      };
      if (newPassword) {
        updatedData.password = newPassword;
      }
      const response = await updateUserInfo(email, updatedData);
      if (response.success) {
        setMessage('User information updated successfully!');
        onStyleUpdate && onStyleUpdate({ backgroundColor: 'lightgreen' });
      } else {
        setMessage(response.error || 'Failed to update user information.');
        onStyleUpdate && onStyleUpdate({ backgroundColor: 'lightcoral' });
      }
    } catch (error) {
      setMessage('Error updating user information.');
      console.error(error);
    }
  };

  return (
    <div className="modal-content custom-modal">
      <div className=".stats-card">
        <h1 className="text-2xl font-bold mb-4" style={{ color: "var(--header-text)" }}>
          Update Your Profile
        </h1>
        <form onSubmit={handleUpdate} className="flex flex-col space-y-6">
          {/* Personal Information */}
          <fieldset className="border p-4 rounded">
            <legend className="text-lg font-semibold" style={{ color: "var(--header-text)" }}>
              Personal Information
            </legend>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="form-group">
                <label htmlFor="firstName" className="label" style={{ color: "var(--text-color)" }}>
                  First Name
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  className="form-input"
                  value={firstName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName" className="label" style={{ color: "var(--text-color)" }}>
                  Last Name
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  className="form-input"
                  value={lastName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email" className="label" style={{ color: "var(--text-color)" }}>
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  className="form-input"
                  value={email}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </fieldset>

          {/* Address Information */}
          <fieldset className="border p-4 rounded">
            <legend className="text-lg font-semibold" style={{ color: "var(--header-text)" }}>
              Address Information
            </legend>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="form-group">
                <label htmlFor="address" className="label" style={{ color: "var(--text-color)" }}>
                  Address
                </label>
                <input
                  id="address"
                  name="address"
                  type="text"
                  className="form-input"
                  value={address}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="city" className="label" style={{ color: "var(--text-color)" }}>
                  City
                </label>
                <input
                  id="city"
                  name="city"
                  type="text"
                  className="form-input"
                  value={city}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="stateVal" className="label" style={{ color: "var(--text-color)" }}>
                  State
                </label>
                <input
                  id="stateVal"
                  name="stateVal"
                  type="text"
                  className="form-input"
                  value={stateVal}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="country" className="label" style={{ color: "var(--text-color)" }}>
                  Country
                </label>
                <input
                  id="country"
                  name="country"
                  type="text"
                  className="form-input"
                  value={country}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phoneNumber" className="label" style={{ color: "var(--text-color)" }}>
                  Phone Number
                </label>
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  className="form-input"
                  value={phoneNumber}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </fieldset>

          {/* Security Settings */}
          <fieldset className="border p-4 rounded">
            <legend className="text-lg font-semibold" style={{ color: "var(--header-text)" }}>
              Security Settings
            </legend>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="form-group">
                <label htmlFor="newPassword" className="label" style={{ color: "var(--text-color)" }}>
                  New Password
                </label>
                <input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  className="form-input"
                  value={newPassword}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword" className="label" style={{ color: "var(--text-color)" }}>
                  Confirm Password
                </label>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  className="form-input"
                  value={confirmPassword}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </fieldset>

          {/* Test Mode Toggle */}
          <div className="form-group flex items-center">
            <input
              id="testMode"
              name="testMode"
              type="checkbox"
              checked={testMode}
              onChange={handleTestModeChange}
            />
            <label htmlFor="testMode" className="ml-2" style={{ color: "var(--text-color)" }}>
              Enable Test Mode (Regtest)
            </label>
          </div>

          <button type="submit" className="button" disabled={!user}>
            Update
          </button>
        </form>
        {message && <p className="message mt-4" style={{ color: "var(--text-color)" }}>{message}</p>}
      </div>
    </div>
  );
};

export default UpdateUserInfo;
